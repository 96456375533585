<nordicite-sign-in-card titlePath="features.sign_in.slug.title" subTitlePath="features.sign_in.slug.sub_title">
    <div [formGroup]="formGroup" class="flex flex-col w-[300px]">
        <mat-form-field appearance="outline" niceControlStatus>
            <mat-label class="input-label">{{ "features.sign_in.slug.label" | translate }}</mat-label>
            <input matInput formControlName="slug">
            <span matSuffix class="font-bold">.nordicite.com</span>
        </mat-form-field>

        <button
            class="fuse-mat-button-large w-full card-button mt-20"
            mat-flat-button
            color="primary"
            (click)="clickContinue()"
        >

            <mat-progress-spinner *ngIf="loading$ | async; else buttonLabel" [diameter]="24" mode="indeterminate"></mat-progress-spinner>
            <ng-template #buttonLabel>
                <span>{{ "features.sign_in.slug.continue" | translate }}</span>
            </ng-template>
        </button>

        <button mat-flat-button class="mt-8" (click)="clickSignInWithPassword()">
            {{ "features.sign_in.authentication.sign_in_with_password" | translate }}
        </button>
    </div>
</nordicite-sign-in-card>
