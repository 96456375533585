<div [formGroup]="formGroup" class="flex flex-col justify-center gap-4">
    <mat-form-field niceControlStatus appearance="outline" class="w-full">
        <mat-label>{{ "components.password_form.password" | translate }}</mat-label>
        <input
            matInput
            formControlName="password"
            type="password"
            class="flex-auto"
        />
    </mat-form-field>

    <mat-form-field niceControlStatus appearance="outline" class="w-full">
        <mat-label>{{ "components.password_form.confirm_password" | translate }}</mat-label>
        <input
            matInput
            formControlName="confirmPassword"
            type="password"
            class="flex-auto"
        />
    </mat-form-field>
</div>
