import { NgModule } from "@angular/core";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { ForgotPasswordQuery } from "./store/forgot-password.query";
import { ForgotPasswordService } from "./store/forgot-password.service";
import { ForgotPasswordStore } from "./store/forgot-password.store";
import { NiceBaseFormModule, NiceLayoutModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { SignInCardModule } from "../sign-in/components/sign-in-card/sign-in-card.module";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { ForgotPasswordFormModule } from "./components/forgot-password-form/forgot-password-form.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [ForgotPasswordComponent],
    providers: [ForgotPasswordQuery, ForgotPasswordService, ForgotPasswordStore],
    imports: [
        NiceLoadingSpinnerModule,
        NiceLayoutModule,
        SignInCardModule,
        CommonModule,
        MatButtonModule,
        NiceBaseFormModule,
        TranslateModule,
        RouterModule,
        ForgotPasswordFormModule,
        MatIconModule
    ],
    exports: [ForgotPasswordComponent]
})
export class ForgotPasswordModule {}
