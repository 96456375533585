import { Component, ViewEncapsulation } from "@angular/core";
import { ForgotPasswordService } from "./store/forgot-password.service";
import { ForgotPasswordQuery } from "./store/forgot-password.query";
import { ResetPasswordForm } from "./components/forgot-password-form/forgot-password-form.form";
import { Router } from "@angular/router";

@Component({
    selector: "nordicite-forgot-password",
    templateUrl: "./forgot-password.template.html",
    styleUrls: ["forgot-password.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ForgotPasswordComponent {
    public loading$ = this.query.selectLoading();
    public emailSent$ = this.query.selectEmailSent();

    constructor(private service: ForgotPasswordService, private query: ForgotPasswordQuery, private router: Router) {}

    public submit(form: ResetPasswordForm): void {
        this.service.forgotPassword(form).subscribe();
    }

    public backToLogin(): void {
        this.router.navigate(["/sign-in"]);
        this.service.resetStore();
    }
}
