import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { DataSources } from "./models/data-sources.model";
import { DataSourcesApi } from "./data-sources.api";

export interface DataSourcesTypeaheadOptions {
    organizationId?: number;
    excludeDisabled?: boolean;
    contextualAlertId?: number;
}

@Injectable()
export class DataSourcesTypeaheadApi extends NiceAsyncTypeaheadProvider<
    DataSources,
    number,
    DataSourcesTypeaheadOptions
> {
    public resource = "dataSources";
    public pageSize = 15;

    constructor(private api: DataSourcesApi) {
        super();
    }

    public getById(id: number, options?: DataSourcesTypeaheadOptions): Promise<DataSources> {
        return lastValueFrom(
            this.api.getById(id, {
                ...(options?.contextualAlertId && { contextualAlertId: options.contextualAlertId })
            })
        );
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: DataSourcesTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<DataSources>> {
        const items = await lastValueFrom(
            this.api.findAll({
                ...options,
                searchQuery,
                page,
                pageSize: this.pageSize,
                include: []
            })
        );

        return {
            items,
            nextPage: items.length === this.pageSize ? page + 1 : null
        };
    }

    public format(item: DataSources): string {
        return item.name as string;
    }
}
