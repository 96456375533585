import { Component, Input, ViewEncapsulation } from "@angular/core";
import { Accounts } from "../../../../api/nordicite/accounts/models/accounts.model";

@Component({
    selector: "nordicite-account-info",
    templateUrl: "./account-info.template.html",
    encapsulation: ViewEncapsulation.None
})
export class AccountInfoComponent {
    @Input()
    public account: Accounts;
}
