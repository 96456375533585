import { Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { TranslateService } from "@ngx-translate/core";
import { I18nUtils } from "../utils/i18n.utils";

@Injectable({ providedIn: "root" })
export class I18nService {
    constructor(private translateService: TranslateService) {}

    public getDefaultLang(): string {
        const browserLang = localStorage.getItem("lang") || (this.translateService.getBrowserLang() as string);
        if (I18nUtils.SUPPORTED_LANGUAGES.some(x => x === browserLang)) {
            return browserLang;
        }

        return I18nUtils.DEFAULT_LANGUAGE;
    }

    public setDefaultLang(lang: string): void {
        localStorage.setItem("lang", lang);
        this.translateService.use(lang);
    }

    public getPaginatorIntl(): MatPaginatorIntl {
        const paginatorIntl = new MatPaginatorIntl();
        paginatorIntl.itemsPerPageLabel = this.translateService.instant("paginator.items_per_page");
        paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);

        return paginatorIntl;
    }

    private getRangeLabel(page: number, pageSize: number, length: number): string {
        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        // If the start index exceeds the lists length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.translateService.instant("paginator.of")} ${length}`;
    }
}
