import { HttpClient } from "@angular/common/http";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { Observable } from "rxjs";
import { IndicatorViews } from "../indicators/models/indicator-views.model";
import { OrganizationGetIndicatorViewForProcessDto } from "./dto/organization-get-indicator-view-for-process.dto";

export class OrganizationIndicatorViewsApi extends _NordiciteBaseApi {
    constructor(organizationId: number, http: HttpClient) {
        super(`organization/${organizationId}/indicator-view`, http);
    }

    public getForProcess(dto: OrganizationGetIndicatorViewForProcessDto): Observable<IndicatorViews> {
        return this.get<IndicatorViews>("", { params: { ...dto }, type: IndicatorViews });
    }
}
