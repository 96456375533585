import { Injectable } from "@angular/core";
import { NavigationHintResolver } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { AppQuery } from "../../../../../../store/app.query";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class OrganizationSettingsNavigationItemHintResolver extends NavigationHintResolver {
    constructor(private appQuery: AppQuery) {
        super();
    }

    public shouldShowHint(): Observable<boolean> {
        return this.appQuery.selectCurrentOrganization().pipe(map(organization => !organization?.guardSms?.length));
    }
}
