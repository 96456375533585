import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { UpsertGatewayDto } from "./dto/upsert-gateway.dto";
import { Gateways } from "./models/gateways.model";
import { UpdateGatewayStatusDto } from "./dto/update-gateway-status.dto";
import { UpdateGatewaySensorStatusDto } from "./dto/update-gateway-sensor-status.dto";
import { UpdateGatewaySensorDto } from "./dto/update-gateway-sensor.dto";

@Injectable()
export class GatewaysApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("gateway");
    }

    public gatewayExistsByGatewayId(gatewayId: string): Observable<boolean> {
        return this.http
            .head(this.url(), {
                params: {
                    gatewayId
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }

                    return throwError(err);
                })
            );
    }

    public getGatewayById(id: string): Observable<Gateways> {
        return this.http.get<Gateways>(this.url(`${id}`));
    }

    public update(id: string, dto: UpsertGatewayDto): Observable<Gateways> {
        return this.http.put<Gateways>(this.url(`${id}`), dto);
    }

    public updateActiveStatus(id: string, dto: UpdateGatewayStatusDto): Observable<Gateways> {
        return this.http.put<Gateways>(this.url(`${id}/status`), dto);
    }

    public updateGatewaySensorActiveStatus(id: string, dto: UpdateGatewaySensorStatusDto): Observable<Gateways> {
        return this.http.put<Gateways>(this.url(`${id}/sensor/status`), dto);
    }

    public updateGatewaySensor(id: string, dto: UpdateGatewaySensorDto): Observable<Gateways> {
        return this.http.put<Gateways>(this.url(`${id}/sensor`), dto);
    }
}
