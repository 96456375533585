import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Mandates } from "./models/mandates.model";
import { MandateAccountsApi } from "./mandate-accounts.api";
import { MandateDataSourcesApi } from "./mandate-data-sources.api";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { UpsertMandateDto } from "./dto/upsert-mandate.dto";
import { BreadcrumbItems } from "@recursyve/ngx-panko";

@Injectable()
export class MandatesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("mandate");
    }

    public accounts(mandateId: number): MandateAccountsApi {
        return new MandateAccountsApi(mandateId, this.http);
    }

    public dataSource(mandateId: number): MandateDataSourcesApi {
        return new MandateDataSourcesApi(mandateId, this.http);
    }

    public getById(id: number): Observable<Mandates> {
        return this.http.get<Mandates>(this.url(`${id}`));
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.http.get<BreadcrumbItems[]>(this.url(`${id}/breadcrumb`));
    }

    public updateMandate(id: number, dto: UpsertMandateDto): Observable<Mandates> {
        return this.http.put<Mandates>(this.url(`${id}`), dto);
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Mandates> {
        return this.http.put<Mandates>(this.url(`${id}/status`), dto);
    }
}
