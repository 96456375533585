import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Router } from "@angular/router";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { ErrorsUtils } from "../../utils/errors.utils";
import { environment } from "../../../environments/environment";
import { AppService } from "../../store/app.service";
import { Observable, catchError } from "rxjs";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastService: NiceToastService, private appService: AppService) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req.clone()).pipe(
            catchError(normalizedError => {
                const err = this.normalizeError(normalizedError, req.url);

                if (err) {
                    throw err;
                }

                throw normalizedError;
            })
        );
    }

    private normalizeError(error: any, api: string): any {
        if (error instanceof Error) {
            return error;
        }

        if (error instanceof HttpErrorResponse) {
            return this.handleHttpError(error, api);
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private handleHttpError(error: HttpErrorResponse, api: string) {
        if (error.url.startsWith(environment.auth0.domain)) {
            return error;
        }

        if (error.status === 401) {
            this.appService.logout().subscribe();
            this.router.navigate(["/sign-in"]);
            this.toastService.warning("errors.401", "401");
            return null;
        } else if (error.status === 403) {
            if (ErrorsUtils.isAuth0TenantLimitReachedErrors(error.error.message)) {
                return error;
            }
            this.router.navigate(["/dashboard/forbidden"]);
            this.toastService.warning("", "403");
            return null;
        }

        if (error.status === 404) {
            return error;
        }

        return error;
    }
}
