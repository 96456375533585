import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AuthorizationStateValidationResult, NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { lastValueFrom } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class OauthResponseService {
    private expectedRedirectPath = environment.applicationOauthPath;

    constructor(private authenticationService: NiceAuthenticationService, private router: Router) {}

    public async checkForOauthResponse(): Promise<void> {
        const response = await lastValueFrom(
            this.authenticationService.checkAuthFlowResponse(this.expectedRedirectPath)
        );

        if (!response || response.stateValidationResult === AuthorizationStateValidationResult.Failure) {
            return;
        }

        await this.router.navigate(["sign-in", "auth"], {
            queryParams: { code: response.code },
            queryParamsHandling: "merge"
        });
    }
}
