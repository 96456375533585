import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EquipmentEntryFilterResult } from "./interfaces/equipment-entries-filter-result.interface";
import { FilterEquipmentEntriesDto } from "./dto/filter-equipment-entries.dto";
import { EquipmentEntries } from "./models/equipment-entries.model";
import { OrganizationData } from "../data-entries/models/organization-data.model";
import { UpdateEquipmentEntryDataDto } from "./dto/update-equipment-entry-data.dto";

export class EquipmentEntriesApi extends _NordiciteBaseApi {
    constructor(equipmentId: number, http: HttpClient) {
        super(`equipment/${equipmentId}/entry`, http);
    }

    public filter(dto: FilterEquipmentEntriesDto): Observable<EquipmentEntryFilterResult> {
        const normalizedDto: any = { ...dto };

        if (dto.startsAt) {
            normalizedDto.startsAt = dto.startsAt.toISOString();
        } else {
            delete normalizedDto.startsAt;
        }

        if (dto.endsAt) {
            normalizedDto.endsAt = dto.endsAt.toISOString();
        } else {
            delete normalizedDto.endsAt;
        }

        return this.get("", { params: normalizedDto, type: EquipmentEntryFilterResult });
    }

    public getById(id: number): Observable<EquipmentEntries> {
        return this.get(`${id}`, { type: EquipmentEntries });
    }

    public getDataById(id: number): Observable<OrganizationData[]> {
        return this.get(`${id}/data`, { type: OrganizationData });
    }

    public sparseUpdateData(id: number, dto: UpdateEquipmentEntryDataDto): Observable<OrganizationData[]> {
        return this.patch(`${id}/data`, dto, { type: OrganizationData });
    }

    public deleteEquipmentEntry(equipmentEntryId: number): Observable<void> {
        return this.delete<void>(`${equipmentEntryId}`);
    }
}
