import { MongoEntities } from "../../common/models/mongo-entities.model";
import { Type } from "class-transformer";
import { Accounts } from "../../accounts/models/accounts.model";
import { ReportConfigurations } from "./report-configurations.model";

export class Reports extends MongoEntities {
    @Type(() => Date)
    createdAt: Date;

    @Type(() => Date)
    updatedAt: Date;

    @Type(() => Date)
    deletedAt?: Date;

    organizationId: number;

    activeConfigurationId: string;

    name: string;

    @Type(() => Date)
    lastGeneratedAt?: Date;

    @Type(() => Date)
    disabledAt?: Date;

    unsignedReportCount?: number;

    disabledByAccountId?: number;

    disabledByAccount?: Accounts;

    @Type(() => ReportConfigurations)
    activeConfiguration?: ReportConfigurations;
}
