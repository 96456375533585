import { IndicatorTypes } from "src/app/api/nordicite/indicators/enums/indicator-types.enum";

export class IndicatorsConstants {
    public static readonly viewGridWidth = 12;

    private static readonly widths = Object.freeze({
        [IndicatorTypes.Separator]: this.viewGridWidth,
        [IndicatorTypes.Group]: 1, // 1 + children's width
        [IndicatorTypes.SimpleValue]: 2,
        [IndicatorTypes.Level]: 2,
        [IndicatorTypes.Ring]: 2,
        [IndicatorTypes.Graph]: this.viewGridWidth
    });

    private static readonly heights = Object.freeze({
        [IndicatorTypes.Separator]: 1,
        [IndicatorTypes.Group]: 2, // 2 + children's height
        [IndicatorTypes.SimpleValue]: 2,
        [IndicatorTypes.Level]: 6,
        [IndicatorTypes.Ring]: 6,
        [IndicatorTypes.Graph]: 10
    });

    public static getTypeWidth(type: IndicatorTypes): number {
        return this.widths[type] ?? 1;
    }

    public static getTypeHeight(type: IndicatorTypes): number {
        return this.heights[type] ?? 1;
    }
}
