import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Organizations } from "../../organizations/models/organizations.model";
import { Laboratories } from "./laboratories.model";
import { OrganizationLaboratoryCredentials } from "./organization-laboratory-credentials.model";
import { EurofinsLaboratories } from "../eurofins/models/eurofins-laboratories.model";
import { Type } from "class-transformer";
import { H2labLaboratories } from "../h2lab/models/h2lab-laboratories.model";

export class OrganizationLaboratories extends DatabaseEntities {
    organizationId: number;
    laboratoryId: number;

    description?: string;
    credentials: OrganizationLaboratoryCredentials;

    organization: Organizations;
    laboratory: Laboratories;

    @Type(() => EurofinsLaboratories)
    eurofins?: EurofinsLaboratories;

    @Type(() => H2labLaboratories)
    h2lab?: H2labLaboratories;
}
