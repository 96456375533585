import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { UpsertGatewayDto } from "../gateways/dto/upsert-gateway.dto";
import { Gateways } from "../gateways/models/gateways.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";

export class DataSourceGatewaysApi extends NordiciteBaseFilterApi<Gateways> {
    constructor(dataSourceId: number, http: HttpClient) {
        super(`data-source/${dataSourceId}/gateway`, http);
    }

    public create(dto: UpsertGatewayDto): Observable<Gateways> {
        return this.http.post<Gateways>(this.url(), dto);
    }

    public gatewayExistsByName(name: string): Observable<boolean> {
        return this.http
            .head(this.url(), {
                params: {
                    name
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }

                    return throwError(err);
                })
            );
    }
}
