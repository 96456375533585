import { NgModule } from "@angular/core";
import { IfSomePermissionDirective } from "./if-some-permission.directive";
import { IfPermissionDirective } from "./if-permission.directive";
import { LocationBackDirective } from "./location-back.directive";
import { AutofocusDirective } from "./autofocus.directive";
import { ImageOnErrorFallbackDirective } from "./image-on-error-fallback.directive";
import { IfNotPermissionDirective } from "./if-not-permission.directive";
import { WidthObserverDirective } from "./width-observer.directive";
import { ProgressSpinnerStylerDirective } from "./progress-spinner-styler.directive";
import { ResolveDirective } from "./resolve.directive";
import { StepperScrollToTopDirective } from "./stepper-scroll-to-top.directive";

@NgModule({
    declarations: [
        IfPermissionDirective,
        IfSomePermissionDirective,
        LocationBackDirective,
        AutofocusDirective,
        ImageOnErrorFallbackDirective,
        IfNotPermissionDirective,
        WidthObserverDirective,
        ProgressSpinnerStylerDirective,
        ResolveDirective,
        StepperScrollToTopDirective
    ],
    exports: [
        IfPermissionDirective,
        IfSomePermissionDirective,
        LocationBackDirective,
        AutofocusDirective,
        ImageOnErrorFallbackDirective,
        IfNotPermissionDirective,
        WidthObserverDirective,
        ProgressSpinnerStylerDirective,
        ResolveDirective,
        StepperScrollToTopDirective
    ]
})
export class DirectivesModule {}
