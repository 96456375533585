import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { MandateDataSources } from "./models/mandate-data-sources.model";
import { CreateMandateDataSourcesDto } from "./dto/create-mandate-data-sources.dto";

export class MandateDataSourcesApi extends NordiciteBaseApi {
    constructor(mandateId: number, private http: HttpClient) {
        super(`mandate/${mandateId}/data-source`);
    }

    public createMandateDataSource(dto: CreateMandateDataSourcesDto): Observable<MandateDataSources[]> {
        return this.http.post<MandateDataSources[]>(this.url(), dto);
    }

    public getAllDataSource(): Observable<MandateDataSources[]> {
        return this.http.get<MandateDataSources[]>(this.url());
    }

    public removeDataSourceFromMandate(dataSourceId: number): Observable<void> {
        return this.http.delete<void>(this.url(`${dataSourceId}`));
    }
}
