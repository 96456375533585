import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Accounts } from "../accounts/models/accounts.model";
import { MandateAccount } from "./models/mandate-account.model";
import { CreateMandateAccountsDto } from "./dto/create-mandate-accounts.dto";

export class MandateAccountsApi extends NordiciteBaseApi {
    constructor(mandateId: number, private http: HttpClient) {
        super(`mandate/${mandateId}/account`);
    }

    public getAllAccounts(): Observable<Accounts[]> {
        return this.http.get<Accounts[]>(this.url());
    }

    public createMandateAccounts(dto: CreateMandateAccountsDto): Observable<MandateAccount[]> {
        return this.http.post<MandateAccount[]>(this.url(), dto);
    }

    public removeAccountFromMandate(accountId: number): Observable<void> {
        return this.http.delete<void>(this.url(`${accountId}`));
    }
}
