import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Roles } from "./models/roles.model";
import { FindRolesDto } from "./dto/find-roles.dto";

@Injectable()
export class RolesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("role");
    }

    public getAll(dto?: FindRolesDto): Observable<Roles[]> {
        return this.http.get<Roles[]>(this.url(), { params: { ...dto } });
    }
}
