import { MongoEntities } from "../../common/models/mongo-entities.model";
import { Type } from "class-transformer";
import { ReportConfigurationColumnValidatorTypes } from "../enums/report-configuration-column-validator-types.enum";
import { Accounts } from "../../accounts/models/accounts.model";
import { ReportConfigurations } from "./report-configurations.model";
import { isDefined } from "class-validator";

export class ReportResults extends MongoEntities {
    @Type(() => Date)
    createdAt: Date;

    @Type(() => Date)
    updatedAt: Date;

    @Type(() => Date)
    deletedAt?: Date;

    reportConfigurationId: string;

    @Type(() => ReportResultRow)
    rows: ReportResultRow[];

    @Type(() => ReportResultSignature)
    signatures: ReportResultSignature[];

    @Type(() => ReportConfigurations)
    reportConfiguration?: ReportConfigurations;

    public get startDate(): Date | null {
        if (!this.rows?.length) {
            return null;
        }

        return this.rows
            .map(row => row.start)
            .reduce((earliest, curr) => {
                if (curr.getTime() < earliest.getTime()) {
                    return curr;
                }

                return curr;
            });
    }

    public totalForColumn(columnConfigurationId: string): number | undefined {
        let total = 0;
        let anyValue = false;

        for (const row of this.rows) {
            const value = row.entries.find(entry => entry.columnConfigurationId === columnConfigurationId)?.value;
            if (!isDefined(value)) {
                continue;
            }

            if (typeof value !== "number") {
                return undefined;
            }

            total += value;
            anyValue = true;
        }

        if (!anyValue) {
            return undefined;
        }

        return total;
    }

    public averageForColumn(columnConfigurationId: string): number | undefined {
        let total = 0;
        let count = 0;

        for (const row of this.rows) {
            const value = row.entries.find(entry => entry.columnConfigurationId === columnConfigurationId)?.value;
            if (!isDefined(value)) {
                continue;
            }

            if (typeof value !== "number") {
                return undefined;
            }

            total += value;
            count++;
        }

        if (count === 0) {
            return undefined;
        }

        return total / count;
    }
}

export class ReportResultRow {
    @Type(() => Date)
    start: Date;

    @Type(() => Date)
    end: Date;

    @Type(() => ReportResultRowEntry)
    entries: ReportResultRowEntry[];
}

export class ReportResultRowEntry {
    columnConfigurationId: string;

    value?: number | string;

    validationErrors: ReportConfigurationColumnValidatorTypes[];
}

export class ReportResultSignature extends MongoEntities {
    @Type(() => Date)
    signedAt: Date;

    accountId: number;

    comments?: string;

    signatureSvg: string;

    account?: Accounts;
}
