import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { Equipments } from "./models/equipments.model";
import { EquipmentsApi } from "./equipments.api";

export interface EquipmentsTypeaheadOptions {
    dataSourceId?: number;
    processId?: number;
    excludeDisabled?: boolean;
    contextualAlertId?: number;
}

@Injectable()
export class EquipmentsTypeaheadApi extends NiceAsyncTypeaheadProvider<Equipments, number, EquipmentsTypeaheadOptions> {
    public resource = "equipments";
    public pageSize = 15;

    constructor(private api: EquipmentsApi) {
        super();
    }

    public getById(id: number, options?: EquipmentsTypeaheadOptions): Promise<Equipments> {
        return lastValueFrom(
            this.api.getById(id, {
                include: ["process"],
                ...(options?.contextualAlertId && { contextualAlertId: options.contextualAlertId })
            })
        );
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: EquipmentsTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<Equipments>> {
        const items = await lastValueFrom(
            this.api.getAll({
                include: ["process"],
                searchQuery,
                page,
                pageSize: this.pageSize,
                ...options
            })
        );

        return {
            items,
            nextPage: items.length === this.pageSize ? page + 1 : null
        };
    }

    public format(item: Equipments): string {
        return (item.name + " - " + item.process.name) as string;
    }
}
