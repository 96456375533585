import { Control, Required } from "@recursyve/ngx-form-generator";
import { IsEmail } from "../../../../validators/is-email.validator";
import { CreateResetPasswordDto } from "../../../../api/nordicite/accounts/dto/create-reset-password.dto";

export class ResetPasswordForm {
    @Control()
    @Required()
    @IsEmail()
    email: string;

    public toDto(): CreateResetPasswordDto {
        return {
            ...this
        };
    }
}
