import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { DataSourceExportSettings } from "./models/data-source-export-settings.model";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { UpsertExportSettingForDataSourceDto } from "./dto/upsert-export-setting-for-data-source.dto";

export class DataSourceExportSettingsApi extends NordiciteBaseApi {
    constructor(dataSourceId: number, private http: HttpClient) {
        super(`data-source/${dataSourceId}/export-setting`);
    }

    public findAll(): Observable<DataSourceExportSettings[]> {
        return this.http.get<DataSourceExportSettings[]>(this.url());
    }

    public getDataSourceExportSettingByName(name: string): Observable<DataSourceExportSettings> {
        return this.http.get<DataSourceExportSettings>(this.url(`name/${name}`));
    }

    public createSettingExport(dto: UpsertExportSettingForDataSourceDto): Observable<DataSourceExportSettings> {
        return this.http.post<DataSourceExportSettings>(this.url(), dto);
    }

    public updateSettingExport(
        id: number,
        dto: UpsertExportSettingForDataSourceDto
    ): Observable<DataSourceExportSettings> {
        return this.http.put<DataSourceExportSettings>(this.url(`${id}`), dto);
    }

    public removeExportSettingFromDataSource(exportSettingId: number): Observable<void> {
        return this.http.delete<void>(this.url(`${exportSettingId}`));
    }
}
