import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Accounts } from "../../accounts/models/accounts.model";
import { IncidentStatuses } from "../enums/incident-statuses.enum";

export class IncidentEvents extends DatabaseEntities {
    incidentId: number;
    escalationLevelId: number;
    accountId: number;
    status: IncidentStatuses;
    comments: string;
    account: Accounts;
}
