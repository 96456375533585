import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { HttpClient } from "@angular/common/http";
import { Accounts } from "../accounts/models/accounts.model";
import { Observable } from "rxjs";
import { UpsertAccountDto } from "../accounts/dto/upsert-account.dto";

export class OrganizationAccountsApi extends NordiciteBaseFilterApi<Accounts> {
    constructor(id: number, http: HttpClient) {
        super(`organization/${id}/account`, http);
    }

    public create(dto: UpsertAccountDto): Observable<Accounts> {
        return this.http.post<Accounts>(this.url(), dto);
    }

    public update(accountId: number, dto: UpsertAccountDto): Observable<Accounts> {
        return this.http.put<Accounts>(this.url(`${accountId}`), dto);
    }
}
