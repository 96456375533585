import { Component, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { ResetPasswordForm } from "./forgot-password-form.form";
import { NiceBaseFormComponent } from "@recursyve/nice-ui-kit.v2";

@Component({
    selector: "nordicite-forgot-password-form",
    templateUrl: "./forgot-password-form.template.html",
    encapsulation: ViewEncapsulation.None,
    providers: NgxFormGeneratorProvider.forFeature([ResetPasswordForm])
})
export class ForgotPasswordFormComponent extends NiceBaseFormComponent<ResetPasswordForm> {
    constructor(public formGroup: GeneratedFormGroup<ResetPasswordForm>) {
        super(formGroup);
    }
}
