<nordicite-sign-in-card titlePath="features.sign_in.authentication.title" [subTitlePath]="subTitlePath">
    <div *ngIf="provider$ | async as provider; else loading" class="flex flex-col">
        <div
            *ngIf="forceUserNamePassword || provider === AuthProviders.Password; else connection"
            [formGroup]="formGroup"
            class="flex w-[300px] flex-col"
        >
            <mat-form-field appearance="outline" niceControlStatus>
                <mat-label class="input-label">
                    {{ "features.sign_in.authentication.email_label" | translate }}
                </mat-label>
                <input matInput type="email" formControlName="email" niceAutofocus />
            </mat-form-field>

            <mat-form-field appearance="outline" niceControlStatus>
                <mat-label class="input-label">
                    {{ "features.sign_in.authentication.password_label" | translate }}
                </mat-label>
                <input matInput type="password" formControlName="password" />
            </mat-form-field>

            <mat-error *ngIf="error$ | async" class="text-center">
                {{ "features.sign_in.authentication.invalid_credentials" | translate }}
            </mat-error>

            <button mat-button (click)="resetPassword()">
                <span>{{ "features.sign_in.authentication.reset_password" | translate }}</span>
            </button>

            <button
                class="fuse-mat-button-large mt-[80px] w-full"
                mat-flat-button
                color="primary"
                (click)="clickSignIn()"
            >
                <mat-progress-spinner
                    *ngIf="loading$ | async; else buttonLabel"
                    [diameter]="24"
                    mode="indeterminate"
                ></mat-progress-spinner>
                <ng-template #buttonLabel>
                    <span>{{ "features.sign_in.authentication.sign_in" | translate }}</span>
                </ng-template>
            </button>
        </div>

        <ng-template #connection>
            <div class="flex flex-col">
                <mat-error *ngIf="error$ | async" class="text-center">
                    {{ "features.sign_in.authentication.invalid_credentials" | translate }}
                </mat-error>

                <button
                    *ngIf="provider === AuthProviders.Google"
                    class="google-button"
                    mat-raised-button
                    (click)="clickSignIn()"
                >
                    <mat-icon svgIcon="nordicite-google-icon" class="mr-[27px]"></mat-icon>
                    <span>{{ "features.sign_in.authentication.sign_in_with_google" | translate }}</span>
                </button>

                <button
                    *ngIf="provider === AuthProviders.Microsoft"
                    class="microsoft-button"
                    mat-stroked-button
                    (click)="clickSignIn()"
                >
                    <mat-icon svgIcon="nordicite-microsoft-icon" class="mr-4 icon-size-6"></mat-icon>
                    <span>{{ "features.sign_in.authentication.sign_in_with_microsoft" | translate }}</span>
                </button>

                <button mat-button (click)="clickUsePassword()" class="mt-6">
                    <span>{{ "features.sign_in.authentication.sign_in_with_password" | translate }}</span>
                </button>
            </div>
        </ng-template>

        <button mat-button nordiciteLocationBack class="mt-2 opacity-50" (click)="reset()">
            <span>{{ "general.back" | translate }}</span>
        </button>
    </div>

    <ng-template #loading>
        <mat-progress-spinner [diameter]="50" mode="indeterminate"></mat-progress-spinner>
    </ng-template>
</nordicite-sign-in-card>
