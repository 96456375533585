import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Accounts } from "../accounts/models/accounts.model";
import { CreateDataSourceAccountsDto } from "./dto/create-data-source-accounts.dto";
import { AccountDataSources } from "./models/data-source-accounts.model";

export class DataSourceAccountsApi extends NordiciteBaseApi {
    constructor(dataSourceId: number, private http: HttpClient) {
        super(`data-source/${dataSourceId}/account`);
    }

    public getAllAccounts(): Observable<Accounts[]> {
        return this.http.get<Accounts[]>(this.url());
    }

    public createDataSourceAccounts(dto: CreateDataSourceAccountsDto): Observable<AccountDataSources[]> {
        return this.http.post<AccountDataSources[]>(this.url(), dto);
    }

    public removeAccountFromDataSource(accountId: number): Observable<void> {
        return this.http.delete<void>(this.url(`${accountId}`));
    }
}
