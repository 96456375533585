import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, UrlSegment } from "@angular/router";
import { Observable } from "rxjs";
import { map, skipWhile } from "rxjs/operators";
import { AppQuery } from "../store/app.query";
import { AppService } from "../store/app.service";

@Injectable({ providedIn: "root" })
export class PermissionsGuard implements CanActivate, CanLoad {
    constructor(private query: AppQuery, private router: Router, private service: AppService) {}

    public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return this.hasPermission(route.data.permissions ?? []);
    }

    public canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
        return this.hasPermission(route.data.permissions ?? []);
    }

    private hasPermission(permissions: string[]): Observable<boolean> {
        return this.query.selectCurrentAccount().pipe(
            skipWhile(account => {
                if (!account) {
                    this.service.loadCurrentAccount().subscribe();
                }
                return !account;
            }),
            map(account => {
                const hasPermissions = permissions.every(permission =>
                    (account.permissions ?? []).includes(permission)
                );
                if (!hasPermissions) {
                    this.router.navigate(["/dashboard"]);
                    return false;
                }
                return true;
            })
        );
    }
}
