import { Injectable } from "@angular/core";
import { Accounts } from "./models/accounts.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { AccountFindOptionsDto } from "./dto/account-find-options.dto";
import { AuthProviders } from "../organizations/models/organizations.model";
import { map } from "rxjs/operators";
import { UpsertAccountDto } from "./dto/upsert-account.dto";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";

@Injectable()
export class AccountApi extends NordiciteBaseFilterApi<Accounts> {
    constructor(http: HttpClient) {
        super("account", http);
    }

    public create(dto: UpsertAccountDto): Observable<Accounts> {
        return this.http.post<Accounts>(this.url(), dto);
    }

    public getInfo(): Observable<Accounts> {
        return this.http.get<Accounts>(this.url("v2/info"));
    }

    public findAll(dto?: AccountFindOptionsDto): Observable<Accounts[]> {
        return this.http.get<Accounts[]>(this.url(), {
            params: {
                ...dto
            }
        });
    }

    public getById(id: number): Observable<Accounts> {
        return this.http.get<Accounts>(this.url(`${id}`));
    }

    public isEmailAlreadyUsed(email: string, provider: AuthProviders): Observable<boolean> {
        return this.http.get<{ used: boolean }>(this.url(`email/${email}?provider=${provider}`)).pipe(map(d => d.used));
    }

    public update(id: number, dto: UpsertAccountDto): Observable<Accounts> {
        return this.http.put<Accounts>(this.url(`${id}`), dto);
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Accounts> {
        return this.http.put<Accounts>(this.url(`${id}/status`), dto);
    }
}
