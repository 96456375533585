import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { UpsertReportDto } from "../reports/dto/upsert-report.dto";
import { Reports } from "../reports/models/reports.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { ReportsSignatureStatus } from "../reports/interfaces/reports-signature-status.interface";
import { map } from "rxjs/operators";

export class OrganizationReportsApi extends NordiciteBaseFilterApi<Reports> {
    constructor(organizationId: number, http: HttpClient) {
        super(`organization/${organizationId}/report`, http);
    }

    public reportExistByName(name: string): Observable<boolean> {
        return this.http
            .head(this.url(), {
                params: {
                    name
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }

                    return throwError(err);
                })
            );
    }

    public create(dto: UpsertReportDto): Observable<Reports> {
        return this.http.post<Reports>(this.url(), dto);
    }

    public getOrganizationReportsSignatureStatus(): Observable<ReportsSignatureStatus[]> {
        return this.http.get<ReportsSignatureStatus[]>(this.url("signature-status"));
    }
}
