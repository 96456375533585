import { SignInOrganizationComponent } from "./sign-in-organization/sign-in-organization.component";
import { SignInAuthenticationComponent } from "./sign-in-authentication/sign-in-authentication.component";
import { NordiciteRoutes } from "../../types/route.type";

export const SIGN_IN_ROUTES: NordiciteRoutes = [
    {
        path: "slug",
        component: SignInOrganizationComponent
    },
    {
        path: "auth",
        component: SignInAuthenticationComponent
    },
    {
        path: "**",
        redirectTo: "slug"
    }
];
