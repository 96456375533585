import { Injectable } from "@angular/core";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UrgencyLevels } from "./models/urgency-levels.model";

@Injectable()
export class UrgencyLevelsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("urgency-level", http);
    }

    public getAll(): Observable<UrgencyLevels[]> {
        return this.get("", { type: UrgencyLevels });
    }
}
