<ng-container *ngIf="{ user: currentUser$ | async, account: currentAccount$ | async } as ctx">
    <div class="flex flex-row items-center justify-between">
        <div class="flex flex-row items-center">
            <div class="w-50" *ngIf="navBarIsCollapsed">
                <img
                    class="w-24 cursor-pointer"
                    src="../../../../../assets/images/logo-waves.svg"
                    [routerLink]="['/']"
                    alt="Nordicité logo"
                />
            </div>
            <div class="flex flex-row">
                <ng-container *ngIf="currentToolbarItem$ | async as currentToolbarItem">
<!--                    TODO uncomment when we have stuff to put here-->
<!--                    <nordicite-toolbar-item-->
<!--                        *ifSomePermission="['nordicite:read:dashboard']"-->
<!--                        [link]="['/dashboard']"-->
<!--                        [selected]="currentToolbarItem === ToolbarItem.Dashboard"-->
<!--                        [label]="'navigation.toolbar.dashboard' | translate"-->
<!--                    ></nordicite-toolbar-item>-->
                    <nordicite-toolbar-item
                        *ifSomePermission="['nordicite:read:organizations-all']"
                        [link]="['/dashboard/organization']"
                        [selected]="currentToolbarItem === ToolbarItem.Organizations"
                        [label]="'navigation.toolbar.organizations' | translate"
                    ></nordicite-toolbar-item>
                    <nordicite-toolbar-item
                        *ifSomePermission="['nordicite:read:accounts-all']"
                        [link]="['/dashboard/user']"
                        [selected]="currentToolbarItem === ToolbarItem.Users"
                        [label]="'navigation.toolbar.users' | translate"
                    ></nordicite-toolbar-item>
                    <nordicite-toolbar-item
                        *ifSomePermission="['nordicite:read:settings']"
                        [link]="['/dashboard/setting']"
                        [selected]="currentToolbarItem === ToolbarItem.Settings"
                        [label]="'navigation.toolbar.settings' | translate"
                    ></nordicite-toolbar-item>
                </ng-container>
            </div>
        </div>
        <div class="menu-button flex h-full w-20 flex-row items-center justify-center">
            <div class="flex flex-auto flex-grow-0 flex-row items-center justify-start">
                <button [matMenuTriggerFor]="userActions" class="user-button" disableRipple mat-button>
                    <div class="flex flex-col justify-center items-center pr-8">
                        <img
                            [src]="ctx.account?.pictureUrl ?? ctx.user?.picture"
                            class="h-8 w-8 rounded-full"
                            nordiciteImageOnErrorFallback
                            [fallback]="fallbackProfilePicture"
                            alt="{{ ctx.account?.fullName }}'s profile picture"
                        />

                        <ng-template #fallbackProfilePicture>
                            <mat-icon class="icon-size-8" svgIcon="streamline-regular:single-neutral-circle"></mat-icon>
                        </ng-template>
                    </div>
                </button>

                <mat-menu class="user-menu" [xPosition]="'before'" #userActions="matMenu">
                    <div *ngIf="ctx.account" class="h-auto px-6 py-4" niceClickStopPropagation>
                        <div class="flex flex-col overflow-hidden leading-none">
                            <span
                                class="inline-block max-w-full overflow-hidden overflow-ellipsis font-medium leading-normal"
                            >
                                {{ ctx.account.fullName }}
                            </span>
                            <span
                                class="mt-1.5 inline-block max-w-full overflow-hidden overflow-ellipsis pb-0.5 leading-normal"
                            >
                                {{ ctx.account.email }}
                            </span>
                        </div>
                    </div>
                    <mat-divider class="m-0"></mat-divider>
                    <button mat-menu-item (click)="logout()">
                        <mat-icon svgIcon="streamline-regular:logout-1" class="h-5 min-h-5 w-5 min-w-5"></mat-icon>
                        <span>{{ "features.dashboard.components.toolbar.sign_out" | translate }}</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
</ng-container>
