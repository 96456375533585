import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { RouterModule } from "@angular/router";
import { NiceLayoutModule, NiceNavigationModule, NiceToggleButtonGroupModule } from "@recursyve/nice-ui-kit.v2";
import { NavbarModule } from "./components/navbar/navbar.module";
import { ToolbarModule } from "./components/toolbar/toolbar.module";
import { DashboardComponent } from "./dashboard.component";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { CommonModule } from "@angular/common";
import { HomeModule } from "./home/home.module";
import { NiceBreadcrumbModule } from "@recursyve/ngx-panko";

@NgModule({
    imports: [
        NiceLayoutModule,
        NiceNavigationModule,
        RouterModule,

        MatButtonModule,
        MatIconModule,
        MatButtonToggleModule,
        NiceToggleButtonGroupModule,

        NavbarModule,
        ToolbarModule,
        CommonModule,
        HomeModule,
        NiceBreadcrumbModule
    ],
    declarations: [DashboardComponent]
})
export class DashboardModule {}
