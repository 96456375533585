import { BaseListLayout } from "@recursyve/nice-data-filter-kit";

export const nordiciteBaseListLayout: BaseListLayout = {
    default: {
        classes: ["flex flex-col h-full"],
        items: [
            {
                classes: ["flex flex-row mb-8"],
                items: [
                    {
                        name: "title",
                        classes: ["flex flex-full basis-1/3"]
                    },
                    {
                        classes: ["flex flex-row flex-auto flex-shrink-0 justify-center items-center basis-1/3"],
                        items: [
                            {
                                name: "search",
                                classes: ["flex flex-auto flex-shrink-0"]
                            }
                        ]
                    },
                    {
                        classes: ["flex flex-auto flex-shrink-0 justify-end basis-1/3"],
                        items: [
                            {
                                name: "prefixButtons",
                                classes: ["flex justify-end items-center"]
                            },
                            {
                                name: "suffixButtons",
                                classes: ["flex justify-end items-center"]
                            }
                        ]
                    }
                ]
            },
            {
                name: "queryBuilder"
            },
            {
                name: "filters"
            },
            {
                name: "content",
                classes: ["h-full"]
            }
        ]
    }
};
