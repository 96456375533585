import { RolesEnum } from "./enums/roles.enum";
import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { Accounts } from "./models/accounts.model";
import { AccountApi } from "./account.api";
import { lastValueFrom } from "rxjs";

interface AccountsTypeaheadOptions {
    organizationIds?: number[];
    role?: RolesEnum;
    includeDisabled?: boolean;
}

@Injectable()
export class AccountsTypeaheadApi extends NiceAsyncTypeaheadProvider<Accounts> {
    public resource = "accounts";
    public pageSize = 15;

    constructor(private api: AccountApi) {
        super();
    }

    public getById(id: number): Promise<Accounts> {
        return lastValueFrom(this.api.getById(id));
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: AccountsTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<Accounts>> {
        const items = await lastValueFrom(
            this.api.findAll({
                searchQuery,
                page,
                pageSize: this.pageSize,
                ...options
            })
        );

        return {
            items,
            nextPage: items.length === this.pageSize ? page + 1 : null
        };
    }

    public format(item: Accounts): string {
        return item.fullName;
    }
}
