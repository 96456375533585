import { _NordiciteBaseApi } from "../../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SignedUrl } from "../../common/models/signed-url.model";
import { H2labAnalysisCertificateMediaTypes } from "./types/h2lab-analysis-certificate-media-types.type";

export class H2labAnalysisCertificateFilesApi extends _NordiciteBaseApi {
    constructor(organizationLaboratoryId: number, httpClient: HttpClient) {
        super(`organization-laboratory/${organizationLaboratoryId}/h2lab/analysis-certificate-file`, httpClient);
    }

    public getSignedUrl(id: number): Observable<SignedUrl> {
        return this.get(`${id}/signed-url`);
    }

    public getMediaTypes(): Observable<H2labAnalysisCertificateMediaTypes> {
        return this.get("media-type");
    }
}
