import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { catchError, Observable, of } from "rxjs";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { EquipmentAttributes } from "./models/equipment-attributes.model";
import { UpdateEquipmentAttributeDto } from "./dto/update-equipment-attribute.dto";
import { GetLatestEquipmentAttributeEntryDto } from "./dto/get-latest-equipment-attribute-entry.dto";
import { UpdateEquipmentAttributeRankDto } from "./dto/update-equipment-attribute-rank.dto";
import { OrganizationData } from "../data-entries/models/organization-data.model";
import { GetEquipmentAttributesDto } from "./dto/get-equipment-attributes.dto";
import { GetEquipmentAttributeDto } from "./dto/get-equipment-attribute.dto";

@Injectable()
export class EquipmentAttributesApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("equipment-attribute", http);
    }

    public getAll(dto: GetEquipmentAttributesDto): Observable<EquipmentAttributes[]> {
        return this.get("", { params: { ...dto }, type: EquipmentAttributes });
    }

    public getById(id: number, dto?: GetEquipmentAttributeDto): Observable<EquipmentAttributes> {
        return this.get(`${id}`, {
            params: { ...dto }
        });
    }

    public getLatestEntry(
        equipmentAttributeId: number,
        dto: GetLatestEquipmentAttributeEntryDto
    ): Observable<OrganizationData> {
        return this.get(`${equipmentAttributeId}/entry/latest`, {
            params: { from: dto.from?.toISOString() },
            type: OrganizationData
        });
    }

    public findLatestEntry(
        equipmentAttributeId: number,
        dto: GetLatestEquipmentAttributeEntryDto
    ): Observable<OrganizationData | null> {
        return this.getLatestEntry(equipmentAttributeId, dto).pipe(catchError(_ => of(null)));
    }

    public updateEquipmentAttributeActiveStatus(
        id: number,
        dto: UpdateActiveStatusDto
    ): Observable<EquipmentAttributes> {
        return this.put(`${id}/status`, dto);
    }

    public update(id: number, dto: UpdateEquipmentAttributeDto): Observable<EquipmentAttributes> {
        return this.put(`${id}`, dto);
    }

    public updateEquipmentAttributeRank(
        id: number,
        dto: UpdateEquipmentAttributeRankDto
    ): Observable<EquipmentAttributes> {
        return this.put(`${id}/rank`, dto);
    }
}
