import { Injectable } from "@angular/core";
import { NavigationHideItemResolver, NiceNavigationItem } from "@recursyve/nice-ui-kit.v2";
import { FlagsmithService } from "../services/flagsmith.service";
import { Observable, of } from "rxjs";

@Injectable({ providedIn: "root" })
export class FlagsmithFeatureFlagNavigationResolver implements NavigationHideItemResolver {
    constructor(private flagsmithService: FlagsmithService) {}

    public shouldHide(item: NiceNavigationItem): Observable<boolean> {
        return of(!this.flagsmithService.hasFeature(item.meta.featureKey));
    }
}
