import { NgModule } from "@angular/core";
import { AccountInfoComponent } from "./account-info.component";
import { DirectivesModule } from "../../../../directives/directives.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [AccountInfoComponent],
    imports: [DirectivesModule, MatIconModule],
    exports: [AccountInfoComponent]
})
export class AccountInfoModule {}
