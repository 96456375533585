import { Injectable } from "@angular/core";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { GetIndicatorInstantDataDto } from "./dto/get-indicator-instant-data.dto";
import { Observable } from "rxjs";
import { UpdateIndicatorDto } from "./dto/update-indicator.dto";
import { Indicators } from "./models/indicators.model";
import { IndicatorInstantData } from "./models/indicator-instant-data.model";
import { IndicatorRangeData } from "./models/indicator-range-data.model";
import { GetIndicatorRangeDataDto } from "./dto/get-indicator-range-data.dto";

@Injectable()
export class IndicatorsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("indicator", http);
    }

    public getIndicatorInstantData(id: number, dto: GetIndicatorInstantDataDto): Observable<IndicatorInstantData> {
        return this.get<IndicatorInstantData>(`${id}/instant`, {
            params: { ...dto, at: dto.at?.toISOString() }
        });
    }

    public getIndicatorRangeData(id: number, dto: GetIndicatorRangeDataDto): Observable<IndicatorRangeData[]> {
        return this.get(`${id}/range`, {
            params: {
                ...dto,
                start: dto.start.toISOString(),
                end: dto.end.toISOString()
            }
        });
    }

    public updateIndicator(indicatorId: number, dto: UpdateIndicatorDto): Observable<Indicators> {
        return this.put(`${indicatorId}`, dto, { type: Indicators });
    }

    public deleteIndicator(indicatorId: number): Observable<void> {
        return this.delete(`${indicatorId}`, { type: Indicators });
    }
}
