import { Component, Input, ViewEncapsulation } from "@angular/core";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
    selector: "nordicite-toolbar-item",
    templateUrl: "toolbar-item.template.html",
    styleUrls: ["toolbar-item.style.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger("selectedLabel", [transition("false <=> true", animate(200))]),
        trigger("selectedBar", [
            state("true", style({ width: "42px" })),
            state("false", style({ width: "0px" })),
            transition("false <=> true", animate(200))
        ])
    ]
})
export class ToolbarItemComponent {
    @Input()
    public link: string[];

    @Input()
    public selected = false;

    @Input()
    public label: string;
}
