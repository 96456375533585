import {
    EquipmentAttributeValidationContext,
    EquipmentAttributeValidationErrors,
    EquipmentAttributeValidator
} from "./equipment-attribute-validator.model";
import { EquipmentAttributeValidatorTypes } from "../equipment-attribute-config.model";
import { defer, EMPTY, Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { isDefined } from "class-validator";
import { Type } from "class-transformer";

export class EquipmentAttributeGreaterThanValidatorOptions {
    public min: number;
}

export class EquipmentAttributeGreaterThanValidator extends EquipmentAttributeValidator<EquipmentAttributeGreaterThanValidatorOptions> {
    public readonly type = EquipmentAttributeValidatorTypes.GreaterThan;

    @Type(() => EquipmentAttributeGreaterThanValidatorOptions)
    public options: EquipmentAttributeGreaterThanValidatorOptions;

    public override validate(
        value: any,
        context: EquipmentAttributeValidationContext
    ): Observable<EquipmentAttributeValidationErrors> {
        if (!isDefined(value) || isNaN(+value)) {
            return EMPTY;
        }

        return defer(() => {
            if (context.targetUnitSameAsEquipmentAttribute) {
                return of(this.options.min);
            }

            return context.convertUnit(this.options.min, context.equipmentAttribute.unitId, context.valueUnitId);
        }).pipe(
            filter(min => isDefined(min) && !isNaN(+min)),
            filter(min => value <= min),
            map(min => ({ equipment_attribute_greater_than: { min } }))
        );
    }
}
