<div [niceLoadingOverlay]="loading">
    <div class="flex flex-col max-h-[95vh] overflow-hidden py-12 column-gap">
        <div class="flex flex-row justify-between gap-4 px-12" [class.items-center]="title">
            <div class="flex flex-col gap-4">
                <h1 *ngIf="title">{{ title }}</h1>
                <span *ngIf="subtitle">{{ subtitle }}</span>
            </div>
            <ng-template [ngIf]="!title">
                <ng-content select="[header]"></ng-content>
            </ng-template>
            <button mat-icon-button (click)="close()">
                <mat-icon svgIcon="streamline-bold:remove" class="icon-size-5"></mat-icon>
            </button>
        </div>
        <div class="px-12 overflow-y-auto">
            <ng-content></ng-content>
        </div>
        <div class="flex flex-row justify-end px-12">
            <ng-content select="[buttons]"></ng-content>
        </div>
    </div>
</div>
