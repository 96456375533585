import { Injectable } from "@angular/core";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Indicators } from "./models/indicators.model";
import { BatchUpdateIndicatorsPositionDto } from "./dto/batch-update-indicators-position.dto";
import { IndicatorViewIndicatorsApi } from "./indicator-view-indicators.api";

@Injectable()
export class IndicatorViewsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("indicator-view", http);
    }

    public indicators(indicatorViewId: number): IndicatorViewIndicatorsApi {
        return new IndicatorViewIndicatorsApi(indicatorViewId, this.httpClient);
    }

    public batchUpdateIndicatorsPositionForIndicatorView(
        indicatorViewId: number,
        dto: BatchUpdateIndicatorsPositionDto
    ): Observable<Indicators[]> {
        return this.put(`${indicatorViewId}/indicator/position/batch`, dto, { type: Indicators });
    }
}
