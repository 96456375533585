import { Injectable } from "@angular/core";
import { ForgotPasswordStore } from "./forgot-password.store";
import { ResetPasswordForm } from "../components/forgot-password-form/forgot-password-form.form";
import { ApiService } from "../../../api/api.service";
import { catchError, delay, EMPTY, Observable, tap } from "rxjs";
import { NiceToastService } from "@recursyve/nice-ui-kit.v2";
import { niceSetLoadingAndError } from "../../../akita/pipes/nice-set-loading-and-error.pipe";

@Injectable()
export class ForgotPasswordService {
    constructor(
        private store: ForgotPasswordStore,
        private apiService: ApiService,
        private toastService: NiceToastService
    ) {}

    public resetStore(): void {
        this.store.reset();
    }

    public forgotPassword(form: ResetPasswordForm): Observable<void> {
        return this.apiService.resetPassword.create(form.toDto()).pipe(
            niceSetLoadingAndError(this.store),
            delay(2000),
            tap(() => {
                this.store.update({
                    emailSent: true
                });
                this.toastService.success("features.forgot_password.toasts.success");
            }),
            catchError(() => {
                this.toastService.error("features.forgot_password.toasts.error");
                return EMPTY;
            })
        );
    }
}
