import { NgModule } from "@angular/core";
import { ForgotPasswordFormComponent } from "./forgot-password-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NiceAutofocusDirectiveModule, NiceFormErrorModule } from "@recursyve/nice-ui-kit.v2";
import { TranslateModule } from "@ngx-translate/core";
import { MatInputModule } from "@angular/material/input";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [ForgotPasswordFormComponent],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        NiceFormErrorModule,
        MatInputModule,
        TranslateModule,
        CommonModule,
        NiceAutofocusDirectiveModule
    ],
    exports: [ForgotPasswordFormComponent]
})
export class ForgotPasswordFormModule {}
