import { HomeComponent } from "./home/home.component";
import { PermissionsGuard } from "../../guards/permissions.guard";
import { HomeGuard } from "../../guards/home.guard";
import { NordiciteRoutes } from "../../types/route.type";

export const DASHBOARD_ROUTES: NordiciteRoutes = [
    {
        path: "",
        component: HomeComponent,
        canActivate: [HomeGuard]
    },
    {
        path: "user",
        loadChildren: () => import("./accounts/accounts.module").then(m => m.AccountsModule),
        canActivate: [PermissionsGuard],
        data: {
            permissions: ["nordicite:read:accounts-all"]
        }
    },
    {
        path: "organization",
        loadChildren: () => import("./organizations/organizations.module").then(m => m.OrganizationsModule),
        canActivate: [PermissionsGuard],
        data: {
            permissions: ["nordicite:read:organizations"]
        }
    },
    {
        path: "setting",
        loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule),
        canActivate: [PermissionsGuard],
        data: {
            permissions: ["nordicite:read:settings"]
        }
    },
    {
        path: "**",
        redirectTo: ""
    }
];
