import { MongoEntities } from "../../common/models/mongo-entities.model";
import { Type } from "class-transformer";
import { ReportConfigurationColumnTypes } from "../enums/report-configuration-column-types.enum";
import { ReportConfigurationColumnValidatorTypes } from "../enums/report-configuration-column-validator-types.enum";
import { Reports } from "./reports.model";
import { EquipmentAttributes } from "../../equipments/models/equipment-attributes.model";
import { Units } from "../../units/models/units.model";
import { ReportGenerationFrequency } from "./report-generation-frequency.model";
import { ReportEntriesTimespan } from "./report-entries-timespan.model";

export class ReportConfigurations extends MongoEntities {
    @Type(() => Date)
    createdAt: Date;

    @Type(() => Date)
    updatedAt: Date;

    @Type(() => Date)
    deletedAt?: Date;

    reportId: string;

    @Type(() => Date)
    startDate?: Date;

    startTime?: number;

    @Type(() => ReportGenerationFrequency)
    generationFrequency: ReportGenerationFrequency;

    @Type(() => ReportEntriesTimespan)
    entriesTimespan: ReportEntriesTimespan;

    @Type(() => ReportConfigurationColumns)
    columns: ReportConfigurationColumns[];

    requiresSignature: boolean;
    showStatistics: boolean;

    report?: Reports;

    public get visibleRootColumns(): ReportConfigurationColumns[] {
        return this.columns.filter(column => !column.isHidden);
    }

    public get humanReadableStartTime(): string | undefined {
        if (!this.startTime) {
            return undefined;
        }

        const hours = Math.floor(this.startTime / 60);
        return `${hours}:00`;
    }
}

export class ReportConfigurationColumns extends MongoEntities {
    isHidden: boolean;
    type: ReportConfigurationColumnTypes;

    @Type(({ object }) => {
        switch ((object as ReportConfigurationColumns)?.type) {
            case ReportConfigurationColumnTypes.Group:
                return ReportConfigurationGroupColumnTypeConfig;
            case ReportConfigurationColumnTypes.EquipmentAttribute:
                return ReportConfigurationEquipmentAttributeColumnTypeConfig;
            case ReportConfigurationColumnTypes.Computed:
                return ReportConfigurationComputedColumnTypeConfig;
            case ReportConfigurationColumnTypes.Dynamic:
                return ReportConfigurationDynamicColumnTypeConfig;
        }
    })
    typeConfig: ReportConfigurationColumnTypeConfigs;
}

export type ReportConfigurationColumnTypeConfigs = ReportConfigurationColumnTypeConfig &
    (
        | ReportConfigurationGroupColumnTypeConfig
        | ReportConfigurationEquipmentAttributeColumnTypeConfig
        | ReportConfigurationComputedColumnTypeConfig
        | ReportConfigurationDynamicColumnTypeConfig
    );

export interface ReportConfigurationColumnTypeConfig {
    title: string;
    subTitle?: string;
}

export class ReportConfigurationGroupColumnTypeConfig implements ReportConfigurationColumnTypeConfig {
    name: string;

    @Type(() => ReportConfigurationColumns)
    columns: ReportConfigurationColumns[];

    public get title(): string {
        return this.name;
    }

    public get visibleColumns(): ReportConfigurationColumns[] {
        return this.columns.filter(column => !column.isHidden);
    }
}

export class ReportConfigurationEquipmentAttributeColumnTypeConfig implements ReportConfigurationColumnTypeConfig {
    name?: string;
    variableName?: string;
    equipmentAttributeId: number;
    unitId?: number;
    fallbackOnGlobalAverage: boolean;
    spreadAverageSinceLastValue: boolean;

    equipmentAttribute?: EquipmentAttributes;
    unit?: Units;

    public get title(): string {
        return this.name || (this.equipmentAttribute?.attribute?.name as string);
    }

    public get subTitle(): string | undefined {
        return (this.unit?.name as string) || (this.equipmentAttribute?.unit?.name as string);
    }
}

export class ReportConfigurationComputedColumnTypeConfig implements ReportConfigurationColumnTypeConfig {
    name: string;
    formula: string;
    spreadAverageSinceLastValue: boolean;

    @Type(() => ReportConfigurationColumnValidator)
    validators: ReportConfigurationColumnValidator[];

    public get title(): string {
        return this.name;
    }
}

export class ReportConfigurationColumnValidator {
    type: ReportConfigurationColumnValidatorTypes;

    @Type(({ object }) => {
        switch ((object as ReportConfigurationColumnValidator)?.type) {
            case ReportConfigurationColumnValidatorTypes.GreaterThan:
                return ReportConfigurationColumnGreaterThanValidatorTypeConfig;
            case ReportConfigurationColumnValidatorTypes.LessThan:
                return ReportConfigurationColumnLessThanValidatorTypeConfig;
            case ReportConfigurationColumnValidatorTypes.Between:
                return ReportConfigurationColumnBetweenValidatorTypeConfig;
        }
    })
    typeConfig: ReportConfigurationColumnValidatorTypeConfigs;
}

export type ReportConfigurationColumnValidatorTypeConfigs = ReportConfigurationColumnValidatorTypeConfig &
    (
        | ReportConfigurationColumnGreaterThanValidatorTypeConfig
        | ReportConfigurationColumnLessThanValidatorTypeConfig
        | ReportConfigurationColumnBetweenValidatorTypeConfig
    );

export interface ReportConfigurationColumnValidatorTypeConfig {
    validationArguments: Record<string, string | number>;
}

export class ReportConfigurationColumnGreaterThanValidatorTypeConfig
    implements ReportConfigurationColumnValidatorTypeConfig {
    min: number;

    public get validationArguments(): Record<string, string | number> {
        return { min: this.min };
    }
}

export class ReportConfigurationColumnLessThanValidatorTypeConfig
    implements ReportConfigurationColumnValidatorTypeConfig {
    max: number;

    public get validationArguments(): Record<string, string | number> {
        return { max: this.max };
    }
}

export class ReportConfigurationColumnBetweenValidatorTypeConfig
    implements ReportConfigurationColumnValidatorTypeConfig {
    min: number;
    max: number;

    public get validationArguments(): Record<string, string | number> {
        return { min: this.min, max: this.max };
    }
}

export class ReportConfigurationDynamicColumnTypeConfig implements ReportConfigurationColumnTypeConfig {
    name: string;
    variables: ReportConfigurationDynamicColumnTypeConfigVariable[];
    formulas: ReportConfigurationDynamicColumnTypeConfigFormula[];

    public get title(): string {
        return this.name;
    }
}

export class ReportConfigurationDynamicColumnTypeConfigVariable {
    name: string;
    equipmentAttributeId: number;
}

export class ReportConfigurationDynamicColumnTypeConfigFormula {
    formula: string;
    output?: string;
}
