<nordicite-base-modal>
    <ng-container header>
        <mat-icon
            *ngIf="config?.type === 'warning'"
            svgIcon="streamline-regular:alert-triangle"
            class="h-24 w-24"
        ></mat-icon>
    </ng-container>

    <div class="flex flex-col gap-6 max-w-100">
        <h2 *ngIf="config?.title" class="whitespace-pre-line" [innerHTML]="config.title"></h2>

        <span *ngIf="config?.message" class="whitespace-pre-line" [innerHTML]="config.message"></span>
    </div>

    <ng-container buttons>
        <div class="flex flex-row gap-4">
            <button *ngFor="let buttonText of config?.buttons" mat-flat-button color="primary" (click)="confirm()">
                {{ buttonText }}
            </button>
        </div>
    </ng-container>
</nordicite-base-modal>
