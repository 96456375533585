import { mixinNiceFilterApi, NiceFilterApi } from "@recursyve/nice-data-filter-kit";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { catchError, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Type } from "@angular/core";
import { _NordiciteBaseApi } from "./nordicite-base.api";

export class NordiciteBaseFilterApi<T> extends NiceFilterApi<T> {
    constructor(path: string, http: HttpClient) {
        super(environment.apiUrl, path, http);
    }

    public findByName(name: string, lang: string): Observable<boolean> {
        return this.http
            .head(this.url(), {
                params: {
                    name: name.toLowerCase(),
                    lang
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }
                    return throwError(err);
                })
            );
    }
}

export const _NordiciteBaseFilterApi = (type?: Type<any>) =>
    mixinNiceFilterApi(type)(
        class extends _NordiciteBaseApi {
            constructor(path: string, httpClient: HttpClient) {
                super(path, httpClient);
            }
        }
    );
