import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewEncapsulation } from "@angular/core";
import { _HintComponentBase } from "@recursyve/nice-ui-kit.v2";

@Component({
    selector: "nordicite-settings-navigation-hint",
    templateUrl: "./organization-settings-navigation-item-hint.template.html",
    encapsulation: ViewEncapsulation.None
})
export class OrganizationSettingsNavigationItemHintComponent extends _HintComponentBase {
    @ViewChild("hint", { static: true })
    public _hint: ElementRef<HTMLElement>;

    constructor(changeDetectorRef: ChangeDetectorRef) {
        super(changeDetectorRef);
    }
}
