import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class CacheApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("cache");
    }

    public invalidateAccessControl(): Observable<void> {
        return this.http.delete<void>(this.url("access-control"));
    }

    public invalidatePermissions(): Observable<void> {
        return this.http.delete<void>(this.url("permission"));
    }
}
