import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { EurofinsPurchaseOrders } from "./eurofins-purchase-orders.model";
import { Type } from "class-transformer";

export class EurofinsLaboratories extends DatabaseEntities {
    organizationLaboratoryId?: number;

    @Type(() => EurofinsPurchaseOrders)
    purchaseOrders?: EurofinsPurchaseOrders[];

    public getPendingConfigurationCount(): number {
        if (!this.purchaseOrders?.length) {
            return 0;
        }

        return this.purchaseOrders.reduce(
            (pendingConfigurationCount, purchaseOrder) =>
                pendingConfigurationCount + purchaseOrder.getPendingConfigurationCount(),
            0
        );
    }
}
