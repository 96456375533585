import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { H2labDataPoints } from "./h2lab-data-points.model";
import { instanceToInstance, Type } from "class-transformer";
import { Equipments } from "../../../equipments/models/equipments.model";
import { EquipmentAttributes } from "../../../equipments/models/equipment-attributes.model";
import { Units } from "../../../units/models/units.model";

export class H2labAccountProjects extends DatabaseEntities {
    laboratoryId?: number;
    h2labAccountId?: number;
    h2labProjectId?: number;
    accountName?: string;
    projectName?: string;
    disabledAt?: Date;
    metadataSynchronizedUntil?: Date;
    dataSynchronizedUntil?: Date;

    @Type(() => H2labDataPoints)
    dataPoints?: H2labDataPoints[];

    public getPendingConfigurationCount(): number {
        if (!this.dataPoints?.length || this.disabledAt) {
            return 0;
        }

        return this.dataPoints.reduce(
            (pendingConfigurationCount, dataPoint) =>
                pendingConfigurationCount + dataPoint.getPendingConfigurationCount(),
            0
        );
    }

    public copyWithResources(resources: {
        equipments: Equipments[];
        equipmentAttributes: EquipmentAttributes[];
        units: Units[];
    }): H2labAccountProjects {
        const clone = instanceToInstance(this);

        if (clone.dataPoints?.length) {
            clone.dataPoints = clone.dataPoints.map(dataPoint => dataPoint.copyWithResources(resources));
        }

        return clone;
    }
}
