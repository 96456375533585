import { NgModule } from "@angular/core";
import { SignInCardComponent } from "./sign-in-card.component";
import { NiceLayoutModule } from "@recursyve/nice-ui-kit.v2";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [SignInCardComponent],
    imports: [NiceLayoutModule, TranslateModule],
    exports: [SignInCardComponent]
})
export class SignInCardModule {}
