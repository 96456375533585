import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { Equipments } from "../equipments/models/equipments.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { CreateEquipmentDto } from "../equipments/dto/create-equipment.dto";

export class DataSourceEquipmentsApi extends NordiciteBaseFilterApi<Equipments> {
    constructor(dataSourceId: number, http: HttpClient) {
        super(`data-source/${dataSourceId}/equipment`, http);
    }

    public equipmentExistsForDataSourceByName(name: string): Observable<boolean> {
        return this.http
            .head(this.url(), {
                params: {
                    name
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }

                    return throwError(() => err);
                })
            );
    }

    public createEquipmentForDataSource(dto: CreateEquipmentDto): Observable<Equipments> {
        return this.http.post<Equipments>(this.url(), dto);
    }
}
