import { NgModule } from "@angular/core";
import { OrganizationLaboratoriesNavigationItemHintComponent } from "./organization-laboratories-navigation-item-hint.component";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [OrganizationLaboratoriesNavigationItemHintComponent],
    imports: [TranslateModule],
    exports: [OrganizationLaboratoriesNavigationItemHintComponent]
})
export class OrganizationLaboratoriesNavigationItemHintModule {}
