import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { GatewayTypes } from "./models/gateway-types.model";

@Injectable()
export class GatewayTypesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("gateway-type");
    }

    public getAllGatewayTypes(): Observable<GatewayTypes[]> {
        return this.http.get<GatewayTypes[]>(this.url());
    }
}
