import { NgModule } from "@angular/core";
import { InvalidLinkComponent } from "./invalid-link.component";
import { SignInCardModule } from "../../sign-in/components/sign-in-card/sign-in-card.module";
import { MatButtonModule } from "@angular/material/button";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [InvalidLinkComponent],
    imports: [SignInCardModule, MatButtonModule, RouterModule, TranslateModule],
    exports: [InvalidLinkComponent]
})
export class InvalidLinkModule {}
