import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { Accounts } from "./models/accounts.model";
import { PasswordDto } from "./dto/password.dto";

@Injectable()
export class SetupPasswordApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("account/setup-password");
    }

    public getAccountFromUniqueCode(code: string): Observable<Accounts> {
        return this.http.get<Accounts>(this.url(`${code}`));
    }

    public updateAccountPasswordFromUniqueCode(code: string, dto: PasswordDto): Observable<void> {
        return this.http.put<void>(this.url(`${code}`), dto);
    }
}
