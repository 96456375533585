import { Control, MatchesControl, Required } from "@recursyve/ngx-form-generator";
import { Password } from "../../../decorators/password.decorator";
import { PasswordDto } from "../../../api/nordicite/accounts/dto/password.dto";

export class PasswordForm {
    @Control()
    @Password()
    @Required()
    public password: string;

    @Control()
    @MatchesControl("password")
    @Required()
    public confirmPassword: string;

    public toDto(): PasswordDto {
        return {
            ...this
        };
    }
}
