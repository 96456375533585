import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { CreateResetPasswordDto } from "./dto/create-reset-password.dto";
import { PasswordDto } from "./dto/password.dto";
import { Accounts } from "./models/accounts.model";

@Injectable()
export class ResetPasswordApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("account/reset-password");
    }

    public create(dto: CreateResetPasswordDto): Observable<void> {
        return this.http.post<void>(this.url(), dto);
    }

    public resetPassword(code: string, dto: PasswordDto): Observable<void> {
        return this.http.put<void>(this.url(`${code}`), dto);
    }

    public getAccountFromUniqueCode(code: string): Observable<Accounts> {
        return this.http.get<Accounts>(this.url(`${code}`));
    }
}
