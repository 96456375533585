import { IndicatorTypeConfigWithVariablesFormula } from "./indicator-type-config.interface";
import { IndicatorTypeConfigThreshold } from "./indicator-type-config-threshold.interface";
import { Type } from "class-transformer";

export class IndicatorLevelTypeConfig extends IndicatorTypeConfigWithVariablesFormula {
    unitId: number;
    decimalPlaces: number;
    minimum: number;
    maximum: number;

    @Type(() => IndicatorTypeConfigThreshold)
    thresholds: IndicatorTypeConfigThreshold[];
}
