import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Attributes } from "./models/attributes.model";
import { UpsertAttributeDto } from "./dto/upsert-attribute.dto";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { SearchAttributesDto } from "./dto/search-attributes.dto";

@Injectable()
export class AttributeApi extends NordiciteBaseFilterApi<Attributes> {
    constructor(http: HttpClient) {
        super("attribute", http);
    }

    public create(dto: UpsertAttributeDto): Observable<Attributes> {
        return this.http.post<Attributes>(this.url(), dto, {
            params: { skipTranslation: true }
        });
    }

    public search(dto: SearchAttributesDto): Observable<Attributes[]> {
        return this.http.get<Attributes[]>(this.url(), {
            params: { ...dto }
        });
    }
    public searchForUnitWithConversion(rawUnitId: number, dto: SearchAttributesDto): Observable<Attributes[]> {
        return this.http.get<Attributes[]>(this.url(`unit/${rawUnitId}`), {
            params: { ...dto }
        });
    }

    public getById(id: number): Observable<Attributes> {
        return this.http.get<Attributes>(this.url(`${id}`));
    }

    public update(id: number, dto: UpsertAttributeDto): Observable<Attributes> {
        return this.http.put<Attributes>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Attributes> {
        return this.http.put<Attributes>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }
}
