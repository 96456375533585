import { Injectable } from "@angular/core";
import { _NordiciteBaseApi } from "../../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { GetH2labApiAccountsDto } from "./dto/get-h2lab-api-accounts.dto";
import { Observable } from "rxjs";
import { H2labApiAccounts } from "./api/h2lab-api-accounts.type";
import { H2labAnalysisCertificatesApi } from "./h2lab-analysis-certificates.api";
import { H2labAnalysisCertificateFilesApi } from "./h2lab-analysis-certificate-files.api";

@Injectable()
export class H2labLaboratoriesApi extends _NordiciteBaseApi {
    constructor(httpClient: HttpClient) {
        super("organization-laboratory/h2lab", httpClient);
    }

    public analysisCertificates(organizationLaboratoryId: number): H2labAnalysisCertificatesApi {
        return new H2labAnalysisCertificatesApi(organizationLaboratoryId, this.httpClient);
    }

    public analysisCertificateFiles(organizationLaboratoryId: number): H2labAnalysisCertificateFilesApi {
        return new H2labAnalysisCertificateFilesApi(organizationLaboratoryId, this.httpClient);
    }

    public getApiAccounts(dto: GetH2labApiAccountsDto): Observable<H2labApiAccounts[]> {
        return this.get("account", { params: dto });
    }
}
