import {
    IndicatorTypeConfigVariable,
    IndicatorTypeConfigWithVariablesFormula
} from "./indicator-type-config.interface";
import { Type } from "class-transformer";

export class IndicatorSimpleValueTypeConfig extends IndicatorTypeConfigWithVariablesFormula {
    unitId: number;
    decimalPlaces: number;
    showTrend: boolean;

    @Type(() => IndicatorTypeConfigVariable)
    variables: IndicatorTypeConfigVariable[];
    formula: string;
}
