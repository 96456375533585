import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { Organizations } from "./models/organizations.model";
import { OrganizationApi } from "./organization.api";
import { lastValueFrom } from "rxjs";

interface OrganizationsTypeaheadOptions {
    fromAccount?: boolean;
}

@Injectable()
export class OrganizationsTypeaheadApi extends NiceAsyncTypeaheadProvider<Organizations> {
    public resource = "organizations";
    public pageSize = 15;

    constructor(private api: OrganizationApi) {
        super();
    }

    public getById(id: number): Promise<Organizations> {
        return lastValueFrom(this.api.getById(id));
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: OrganizationsTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<Organizations>> {
        const items = await lastValueFrom(
            this.api.search({
                searchQuery,
                page,
                pageSize: this.pageSize,
                fromAccount: options?.fromAccount
            })
        );

        return {
            items,
            nextPage: items.length === this.pageSize ? page + 1 : null
        };
    }

    public format(item: Organizations): string {
        return item.name;
    }
}
