import { DatabaseEntities } from "../../common/models/database-entities.model";
import { LanguageEnums } from "../../common/enums/language.enums";
import { Coords } from "../../coords/models/coords.model";
import { Accounts } from "../../accounts/models/accounts.model";

export enum AuthProviders {
    Password = "auth0",
    Google = "oauth2:google",
    Microsoft = "oauth2:microsoft"
}

export interface Organizations extends DatabaseEntities {
    coordId: number;
    auth0ConnectionId: string;
    authProvider: AuthProviders;
    slug: string;
    name: string;
    logoUrl: string;
    language: LanguageEnums;
    disabledAt?: Date;
    disabledByAccountId: number;
    guardSms: string[];

    disabledByAccount?: Accounts;
    coord: Coords;
}
