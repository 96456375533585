import { NgModule } from "@angular/core";
import { CustomAlertComponent } from "./custom-alert.component";
import { BaseModalModule } from "../base-modal/base-modal.module";
import { MatIconModule } from "@angular/material/icon";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    declarations: [CustomAlertComponent],
    imports: [BaseModalModule, MatIconModule, CommonModule, MatButtonModule],
    exports: [CustomAlertComponent]
})
export class CustomAlertModule {}
