import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import { AppQuery } from "../../store/app.query";

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
    constructor(private appQuery: AppQuery) {}

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const language = this.appQuery.getValue().currentAccount?.language;

        if (!language) {
            return next.handle(req);
        }

        return next.handle(
            req.clone({
                headers: req.headers.set("Accept-Language", language)
            })
        );
    }
}
