import { DatabaseEntities } from "../../common/models/database-entities.model";
import { AlertStatuses } from "../enums/alert-statuses.enum";
import { Accounts } from "../../accounts/models/accounts.model";
import { AlertConfigurations } from "./alert-configurations.model";
import { Type } from "class-transformer";

export class Alerts extends DatabaseEntities {
    organizationId: number;
    activeConfigurationId: number;
    disabledByAccountId?: number;
    status: AlertStatuses;
    name: string;
    description: string;

    @Type(() => Date)
    disabledAt?: Date;
    disabledReason?: string;
    triggeredIncidentCount: number;
    lastIncidentTriggeredAt: Date;

    @Type(() => AlertConfigurations)
    activeConfiguration: AlertConfigurations;

    disabledByAccount?: Accounts;
}
