export interface AlertConfigurationValidatorTypeConfig {}

export class AlertConfigurationValidatorGreaterThanTypeConfig implements AlertConfigurationValidatorTypeConfig {
    min: number;
}

export class AlertConfigurationValidatorLessThanTypeConfig implements AlertConfigurationValidatorTypeConfig {
    max: number;
}

export class AlertConfigurationValidatorBetweenTypeConfig implements AlertConfigurationValidatorTypeConfig {
    min: number;
    max: number;
}
