import { NgModule } from "@angular/core";
import { PasswordFormComponent } from "./password-form.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { NiceFormErrorModule } from "@recursyve/nice-ui-kit.v2";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [PasswordFormComponent],
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        NiceFormErrorModule,
        MatInputModule,
        TranslateModule,
        CommonModule
    ],
    exports: [PasswordFormComponent]
})
export class PasswordFormModule {}
