import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { SetupPasswordService } from "./store/setup-password.service";
import { SetupPasswordQuery } from "./store/setup-password.query";
import { ActivatedRoute, Router } from "@angular/router";
import { filter, switchMap, take } from "rxjs/operators";
import { PasswordForm } from "../components/password-form/password-form.form";

@Component({
    selector: "nordicite-setup-password",
    templateUrl: "./setup-password.template.html",
    styleUrls: ["setup-password.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SetupPasswordComponent implements OnInit {
    public loading$ = this.query.selectLoading();
    public account$ = this.query.selectAccount();
    public codeInvalid$ = this.query.selectCodeInvalid();
    public passwordUpdated$ = this.query.selectPasswordUpdated();

    constructor(
        private service: SetupPasswordService,
        private query: SetupPasswordQuery,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {}

    public ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(
                take(1),
                filter(params => params.has("code")),
                switchMap(params => this.service.validateCode(params.get("code")))
            )
            .subscribe();
    }

    public submit(form: PasswordForm): void {
        this.service.updatePassword(form).subscribe();
    }

    public backToLogin(): void {
        this.router.navigate(["/sign-in"]);
        this.service.resetStore();
    }
}
