import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { Location } from "@angular/common";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { Router } from "@angular/router";
import { AppService } from "../../../../store/app.service";
import { AppQuery } from "../../../../store/app.query";
import { Subject } from "rxjs";
import { Auth0Users } from "../../../../api/nordicite/auth0/users/models/users.model";
import { ToolbarItem } from "./toolbar.item";

@Component({
    selector: "nordicite-toolbar",
    templateUrl: "toolbar.template.html",
    styleUrls: ["./toolbar.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ToolbarComponent implements OnInit {
    @Input()
    public navBarIsCollapsed: boolean;

    public currentAccount$ = this.appQuery.selectCurrentAccount();
    public currentToolbarItem$ = this.appQuery.selectCurrentToolbarItem();
    public currentUser$: Subject<Auth0Users> = this.authService.currentUser$;

    public ToolbarItem = ToolbarItem;

    constructor(
        private appQuery: AppQuery,
        private appService: AppService,
        private router: Router,
        private location: Location,
        private authService: NiceAuthenticationService
    ) {}

    public ngOnInit() {
        const currentItem = Object.values(ToolbarItem).find(item =>
            this.location.path().startsWith(`/dashboard/${item}`)
        );
        if (currentItem) {
            this.appService.updateCurrentToolbarItem(currentItem);
        } else {
            this.appService.updateCurrentToolbarItem(ToolbarItem.Organizations);
            this.router.navigate(["/dashboard/organization"]);
        }

        this.location.onUrlChange((url, _) => this.onUrlChange(url));
    }

    public async logout(): Promise<void> {
        this.appService.logout().subscribe();
        await this.router.navigate(["/sign-in"]);
    }

    private onUrlChange(url: string): void {
        if (url.startsWith("/dashboard/")) {
            url = url.replace("/dashboard/", "");
        }

        if (url.includes("?")) {
            url = url.split("?")[0];
        }

        let item = ToolbarItem.Organizations;
        for (const i of Object.values(ToolbarItem)) {
            if (url.startsWith(i)) {
                item = i;
                break;
            }
        }

        if (item !== this.appQuery.getValue().currentToolbarItem) {
            this.appService.updateCurrentToolbarItem(item);
        }
    }
}
