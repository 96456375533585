import { Injectable } from "@angular/core";
import { _NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { ReportResults } from "./models/report-results.model";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { UpdateReportResultDto } from "./dto/update-report-result.dto";
import { FilterQueryModel } from "@recursyve/nice-data-filter-kit";
import { ReportResultExportationFormats } from "./enums/report-result-exportation-formats.enum";

@Injectable()
export class ReportResultsApi extends _NordiciteBaseFilterApi(ReportResults) {
    constructor(http: HttpClient) {
        super("report-result", http);
    }

    public getById(id: string): Observable<ReportResults> {
        return this.get(id, { type: ReportResults });
    }

    public getBreadcrumbsById(id: string): Observable<BreadcrumbItems[]> {
        return this.get(`${id}/breadcrumb`);
    }

    public updateById(id: string, dto: UpdateReportResultDto): Observable<ReportResults> {
        return this.put(id, dto, { type: ReportResults });
    }

    public downloadDataResponse(
        type: ReportResultExportationFormats,
        filterQuery: FilterQueryModel
    ): Observable<HttpResponse<Blob>> {
        return this.httpClient.post(this.url(`download/${type}`), filterQuery, {
            responseType: "blob",
            observe: "response"
        });
    }
}
