import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { EurofinsDataPoints } from "./eurofins-data-points.model";

export class EurofinsAnalysisCertificates extends DatabaseEntities {
    dataPointId?: number;
    eurofinsDocumentId?: string;
    fileId?: string;
    sampler: string;
    samplingDate?: Date;

    dataPoint?: EurofinsDataPoints;
}
