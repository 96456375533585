import { registerLocaleData } from "@angular/common";
import localeEn from "@angular/common/locales/en";
import localeFr from "@angular/common/locales/fr";
import { Component, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { AppService } from "./store/app.service";
import { NavigationService } from "./navigation/navigation.service";
import { OauthResponseService } from "./providers/oauth-response.service";

@Component({
    selector: "nordicite-app",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.style.scss"]
})
export class AppComponent implements OnInit {
    constructor(
        private appService: AppService,
        private navigationService: NavigationService,
        private oauthResponseService: OauthResponseService,
        private translateService: TranslateService,
        private matIconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer
    ) {}

    public async ngOnInit(): Promise<void> {
        this.appService.init();

        // Handle Authorization flow response
        await this.oauthResponseService.checkForOauthResponse();

        this.matIconRegistry.setDefaultFontSetClass("material-icons-outlined");
        this.matIconRegistry.addSvgIconSetInNamespace(
            "streamline-regular",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/regular-icons.svg")
        );
        this.matIconRegistry.addSvgIconSetInNamespace(
            "streamline-bold",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/bold-icons.svg")
        );

        this.matIconRegistry.addSvgIconSetInNamespace(
            "heroicons_outline",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/heroicons-outline.svg")
        );
        this.matIconRegistry.addSvgIconSetInNamespace(
            "heroicons_solid",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/icons/heroicons-solid.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "nordicite-google-icon",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/images/google-logo.svg")
        );
        this.matIconRegistry.addSvgIcon(
            "nordicite-microsoft-icon",
            this.sanitizer.bypassSecurityTrustResourceUrl("assets/images/microsoft-logo.svg")
        );

        this.appService.loadServerState().subscribe();
        await this.navigationService.init();

        const lang = localStorage.getItem("lang") || (this.translateService.getBrowserLang() as string);
        localStorage.setItem("lang", lang);
        this.updateLang(lang);
    }

    private updateLang(lang: string): void {
        this.translateService.setDefaultLang(lang);
        registerLocaleData(localeFr);
        registerLocaleData(localeEn);
    }
}
