import { SignInAuthenticationState, SignInAuthenticationStore } from "./sign-in-authentication.store";
import { Query } from "@datorama/akita";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

@Injectable()
export class SignInAuthenticationQuery extends Query<SignInAuthenticationState> {
    constructor(protected store: SignInAuthenticationStore) {
        super(store);
    }

    public selectAuthConnection(): Observable<string> {
        return this.select("authConnection");
    }

    public selectAuthProvider(): Observable<string> {
        return this.select("authProvider");
    }
}
