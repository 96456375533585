import { NgModule } from "@angular/core";
import { SignInOrganizationComponent } from "./sign-in-organization.component";
import { SignInCardModule } from "../components/sign-in-card/sign-in-card.module";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { NiceFormErrorModule } from "@recursyve/nice-ui-kit.v2";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { SignInOrganizationStore } from "./store/sign-in-organization.store";
import { SignInOrganizationQuery } from "./store/sign-in-organization.query";
import { SignInOrganizationService } from "./store/sign-in-organization.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { CommonModule } from "@angular/common";

@NgModule({
    imports: [
        SignInCardModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NiceFormErrorModule,
        TranslateModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        CommonModule
    ],
    declarations: [SignInOrganizationComponent],
    providers: [SignInOrganizationStore, SignInOrganizationQuery, SignInOrganizationService],
    exports: [SignInOrganizationComponent]
})
export class SignInOrganizationModule {}
