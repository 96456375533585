import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { NordiciteBaseApi } from "../nordicite-base.api";

@Injectable()
export class UniqueCodeApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("unique-code");
    }

    public validate(code: string): Observable<boolean> {
        return this.http.get<boolean>(this.url(`${code}`));
    }
}
