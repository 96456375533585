import { Injectable } from "@angular/core";
import { _NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Alerts } from "./models/alerts.model";
import { HttpClient } from "@angular/common/http";
import { GetAlertDto } from "./dto/get-alert.dto";
import { Observable } from "rxjs";
import { UpsertAlertDto } from "./dto/upsert-alert.dto";
import { PartialUpdateAlertDto } from "./dto/partial-update-alert.dto";

@Injectable()
export class AlertsApi extends _NordiciteBaseFilterApi(Alerts) {
    constructor(http: HttpClient) {
        super("alert", http);
    }

    public getById(id: number, dto?: GetAlertDto): Observable<Alerts> {
        return this.get(`${id}`, {
            params: {
                ...dto
            }
        });
    }

    public update(id: number, dto: UpsertAlertDto): Observable<Alerts> {
        return this.put(`${id}`, dto);
    }

    public partialUpdate(id: number, dto: PartialUpdateAlertDto): Observable<Alerts> {
        return this.patch(`${id}`, dto);
    }
}
