import { Type } from "class-transformer";

export class EquipmentAttributeComputationConfig {
    @Type(() => EquipmentAttributeComputationVariableConfig)
    variables: EquipmentAttributeComputationVariableConfig[];
    formula: string;
    spreadDailyAverageSinceLastEntry: boolean;
}

export enum EquipmentAttributeComputationVariableTypes {
    Current = "current",
    Previous = "previous"
}

export class EquipmentAttributeComputationVariableConfig {
    name: string;
    equipmentAttributeId: number;
    type: EquipmentAttributeComputationVariableTypes;
}
