import { environment } from "../../../environments/environment";
import { mixinNiceApi, NiceHttpExceptionFactory } from "@recursyve/nice-ui-kit.v2";
import { HttpClient } from "@angular/common/http";

export class NordiciteBaseApi {
    private readonly apiUrl = environment.apiUrl;
    private readonly path: string;

    constructor(path: string) {
        this.path = path;
    }

    protected url(route = ""): string {
        if (route === "" && this.path === "") {
            return this.apiUrl;
        }
        return `${this.apiUrl}/${this.path}/${route}`;
    }
}

export const _NordiciteBaseApi = mixinNiceApi(
    class {
        public readonly apiUrl = environment.apiUrl;

        protected readonly _exceptionFactory: NiceHttpExceptionFactory;

        public get exceptionFactory(): NiceHttpExceptionFactory {
            return this._exceptionFactory;
        }

        constructor(public path: string, public httpClient: HttpClient) {}
    }
);
