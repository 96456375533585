import { Injectable } from "@angular/core";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Laboratories } from "./models/laboratories.model";

@Injectable()
export class LaboratoriesApi extends _NordiciteBaseApi {
    constructor(httpClient: HttpClient) {
        super("laboratory", httpClient);
    }

    public getAll(): Observable<Laboratories[]> {
        return this.get();
    }
}
