import { NgModule } from "@angular/core";
import { SignInAuthenticationComponent } from "./sign-in-authentication.component";
import { SignInCardModule } from "../components/sign-in-card/sign-in-card.module";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { NiceAutofocusDirectiveModule, NiceFormErrorModule } from "@recursyve/nice-ui-kit.v2";
import { SignInAuthenticationStore } from "./store/sign-in-authentication.store";
import { SignInAuthenticationQuery } from "./store/sign-in-authentication.query";
import { SignInAuthenticationService } from "./store/sign-in-authentication.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatIconModule } from "@angular/material/icon";
import { DirectivesModule } from "../../../directives/directives.module";

@NgModule({
    imports: [
        SignInCardModule,
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule,
        MatButtonModule,
        MatInputModule,
        NiceFormErrorModule,
        MatProgressSpinnerModule,
        MatIconModule,
        DirectivesModule,
        NiceAutofocusDirectiveModule
    ],
    declarations: [SignInAuthenticationComponent],
    providers: [SignInAuthenticationStore, SignInAuthenticationQuery, SignInAuthenticationService],
    exports: [SignInAuthenticationComponent]
})
export class SignInAuthenticationModule {}
