import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { Observable } from "rxjs";
import { DataSources } from "./models/data-sources.model";
import { UpdateDataSourceDto } from "./dto/update-data-source.dto";
import { DataSourceAccountsApi } from "./data-source-accounts.api";
import { DataSourceEquipmentsApi } from "./data-source-equipments.api";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { GetDataSourceDto } from "./dto/get-data-source.dto";
import { DataSourceExportSettingsApi } from "./data-source-export-settings.api";
import { DataSourceReportsApi } from "./data-source-reports.api";
import { Mandates } from "../mandates/models/mandates.model";
import { GetDataSourcesMandatesFindOptionsDto } from "./dto/get-data-sources-mandates-find-options.dto";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { DataSourceGatewaysApi } from "./data-source-gateways.api";
import { UpdateDataSourceRankDto } from "./dto/update-data-source-rank.dto";
import { GetDataSourcesDto } from "./dto/get-data-sources.dto";

@Injectable()
export class DataSourcesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("data-source");
    }

    public accounts(dataSourceId: number): DataSourceAccountsApi {
        return new DataSourceAccountsApi(dataSourceId, this.http);
    }

    public equipments(dataSourceId: number): DataSourceEquipmentsApi {
        return new DataSourceEquipmentsApi(dataSourceId, this.http);
    }

    public exportSettings(dataSourceId: number): DataSourceExportSettingsApi {
        return new DataSourceExportSettingsApi(dataSourceId, this.http);
    }

    public gateways(dataSourceId: number): DataSourceGatewaysApi {
        return new DataSourceGatewaysApi(dataSourceId, this.http);
    }

    public reports(dataSourceId: number): DataSourceReportsApi {
        return new DataSourceReportsApi(dataSourceId, this.http);
    }

    public findAll(dto: GetDataSourcesDto): Observable<DataSources[]> {
        return this.http.get<DataSources[]>(this.url(), {
            params: { ...dto }
        });
    }

    public getById(id: number, dto?: GetDataSourceDto): Observable<DataSources> {
        return this.http.get<DataSources>(this.url(`${id}`), {
            params: { ...dto }
        });
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.http.get<BreadcrumbItems[]>(this.url(`${id}/breadcrumb`));
    }

    public getDataSourceMandates(id: number, dto: GetDataSourcesMandatesFindOptionsDto): Observable<Mandates[]> {
        return this.http.get<Mandates[]>(this.url(`${id}/mandate`), { params: { ...dto } });
    }

    public update(id: number, dto: UpdateDataSourceDto): Observable<DataSources> {
        return this.http.put<DataSources>(this.url(`${id}`), dto);
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<DataSources> {
        return this.http.put<DataSources>(this.url(`${id}/status`), dto);
    }

    public updateDataSourceRank(id: number, dto: UpdateDataSourceRankDto): Observable<DataSources> {
        return this.http.put<DataSources>(this.url(`${id}/rank`), dto);
    }
}
