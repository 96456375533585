import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { OrganizationLaboratories } from "./models/organization-laboratories.model";
import { Injectable } from "@angular/core";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { UpdateOrganizationLaboratoryDto } from "./dto/update-organization-laboratory.dto";
import { EurofinsAnalysisCertificatesApi } from "./eurofins/eurofins-analysis-certificates.api";
import { GetOrganizationLaboratoriesConfigurationStatusDto } from "./dto/get-organization-laboratories-configuration-status.dto";
import { OrganizationLaboratoriesConfigurationStatus } from "./types/organization-laboratories-configuration-status.type";
import { CreateOrganizationLaboratoryDto } from "./dto/create-organization-laboratory.dto";
import { _NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { H2labLaboratoriesApi } from "./h2lab/h2lab-laboratories.api";

@Injectable()
export class OrganizationLaboratoriesApi extends _NordiciteBaseFilterApi(OrganizationLaboratories) {
    constructor(http: HttpClient, public readonly h2lab: H2labLaboratoriesApi) {
        super("organization-laboratory", http);
    }

    public eurofinsAnalysisCertificates(organizationLaboratoryId: number): EurofinsAnalysisCertificatesApi {
        return new EurofinsAnalysisCertificatesApi(organizationLaboratoryId, this.httpClient);
    }

    public create(dto: CreateOrganizationLaboratoryDto): Observable<OrganizationLaboratories> {
        return this.post("", dto, { type: OrganizationLaboratories });
    }

    public getById(id: number): Observable<OrganizationLaboratories> {
        return this.get(`${id}`, { type: OrganizationLaboratories });
    }

    public getConfigurationStatus(
        dto: GetOrganizationLaboratoriesConfigurationStatusDto
    ): Observable<OrganizationLaboratoriesConfigurationStatus> {
        return this.get("configuration-status", { params: dto });
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.get(`${id}/breadcrumb`);
    }

    public update(id: number, dto: UpdateOrganizationLaboratoryDto): Observable<OrganizationLaboratories> {
        return this.put(`${id}`, dto, { type: OrganizationLaboratories });
    }
}
