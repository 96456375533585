import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { GenerateDataSourceOperationConditionReportDto } from "./dto/generate-data-source-operation-condition-report.dto";
import { Observable } from "rxjs";

export class DataSourceReportsApi extends NordiciteBaseApi {
    constructor(dataSourceId: number, private http: HttpClient) {
        super(`data-source/${dataSourceId}/report`);
    }

    public generateOperationConditionReportResponse(
        dto: GenerateDataSourceOperationConditionReportDto
    ): Observable<HttpResponse<Blob>> {
        return this.http.post(this.url("operation-condition"), dto, {
            responseType: "blob",
            observe: "response"
        });
    }
}
