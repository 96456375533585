import { Injectable } from "@angular/core";
import { SignInOrganizationStore } from "./sign-in-organization.store";
import { UserOrganization, UserOrganizationService } from "../../../../providers/user-organization.service";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { niceSetLoadingAndError } from "../../../../akita/pipes/nice-set-loading-and-error.pipe";

@Injectable()
export class SignInOrganizationService {
    constructor(
        private store: SignInOrganizationStore,
        private userOrganizationService: UserOrganizationService,
        private router: Router
    ) {}

    public reset(): void {
        this.store.reset();
    }

    public resetError(): void {
        this.store.setError(null);
    }

    public init(): Observable<UserOrganization> {
        return this.userOrganizationService.loadUserOrganization().pipe(niceSetLoadingAndError(this.store));
    }

    public selectOrganization(slug: string): Observable<UserOrganization> {
        return this.userOrganizationService.setUserOrganization(slug?.trim()).pipe(
            niceSetLoadingAndError(this.store),
            map(userOrganization => {
                if (userOrganization) {
                    return userOrganization;
                }
                this.store.setError({ invalid_slug: true });
                return null;
            })
        );
    }

    public signInWithPassword(): void {
        this.store.setError(null);
        this.router.navigate(["sign-in", "auth"]);
    }
}
