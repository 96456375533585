import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Processes } from "./models/processes.model";
import { Observable } from "rxjs";
import { FindByNameApi } from "../settings/interfaces/find-by-name-api.interface";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { UpdateProcessRankDto } from "./dto/update-process-rank.dto";
import { ProcessesFindOptionsDto } from "./dto/processes-find-options.dto";
import { UpsertProcessDto } from "./dto/upsert-process.dto";

@Injectable()
export class ProcessesApi extends NordiciteBaseFilterApi<Processes> implements FindByNameApi {
    constructor(http: HttpClient) {
        super("process", http);
    }

    public getAll(dto?: ProcessesFindOptionsDto): Observable<Processes[]> {
        return this.http.get<Processes[]>(this.url(), {
            params: {
                ...dto
            }
        });
    }

    public create(dto: UpsertProcessDto): Observable<Processes> {
        return this.http.post<Processes>(this.url(), dto);
    }

    public update(id: number, dto: UpsertProcessDto): Observable<Processes> {
        return this.http.put<Processes>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Processes> {
        return this.http.put<Processes>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateProcessRank(id: number, dto: UpdateProcessRankDto): Observable<Processes> {
        return this.http.put<Processes>(this.url(`${id}/rank`), dto, { params: { skipTranslation: true } });
    }
}
