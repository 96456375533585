import { TimeSeriesEntities } from "../../common/models/time-series-entities.model";
import { OrganizationDataTypesEnum } from "../enums/organization-data-types.enum";
import { Type } from "class-transformer";

export class OrganizationDataMetadata {
    type: OrganizationDataTypesEnum;
    equipmentAttributeId: number;
    rawUnitId?: number;
    gatewayId?: number;
    sensorId?: number;
    equipmentEntryId?: number;

    @Type(() => Date)
    updatedAt?: Date;
    updatedByAccountId?: number;
}

export class OrganizationData extends TimeSeriesEntities<OrganizationDataMetadata> {
    @Type(() => OrganizationDataMetadata)
    metadata: OrganizationDataMetadata;
    rawValue: number | string;
    value: number | string;
}
