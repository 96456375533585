import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { SignedUrl } from "../common/models/signed-url.model";

export class JournalImageApi extends NordiciteBaseApi {
    constructor(journalId: number, private http: HttpClient) {
        super(`journal/${journalId}/image`);
    }

    public getSignedUrl(imageId: number): Observable<SignedUrl> {
        return this.http.get<SignedUrl>(this.url(`${imageId}/signed-url`));
    }
}
