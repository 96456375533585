import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { FindByNameApi } from "../settings/interfaces/find-by-name-api.interface";
import { EquipmentCategories } from "./models/equipment-categories.model";
import { Observable } from "rxjs";
import { UpsertSettingDto } from "../settings/dto/upsert-setting.dto";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";

@Injectable()
export class EquipmentCategoriesApi extends NordiciteBaseFilterApi<EquipmentCategories> implements FindByNameApi {
    constructor(http: HttpClient) {
        super("equipment-category", http);
    }

    public getAll(): Observable<EquipmentCategories[]> {
        return this.http.get<EquipmentCategories[]>(this.url());
    }

    public create(dto: UpsertSettingDto): Observable<EquipmentCategories> {
        return this.http.post<EquipmentCategories>(this.url(), dto);
    }

    public update(id: number, dto: UpsertSettingDto): Observable<EquipmentCategories> {
        return this.http.put<EquipmentCategories>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<EquipmentCategories> {
        return this.http.put<EquipmentCategories>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }
}
