import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { ResetPasswordStore, ResetPasswordState } from "./reset-password.store";
import { Observable } from "rxjs";

@Injectable()
export class ResetPasswordQuery extends Query<ResetPasswordState> {
    constructor(store: ResetPasswordStore) {
        super(store);
    }

    public selectCodeInvalid(): Observable<boolean> {
        return this.select(s => s.codeInvalid);
    }

    public selectPasswordUpdated(): Observable<boolean> {
        return this.select(s => s.passwordUpdated);
    }
}
