import { Type } from "class-transformer";

export class DatabaseEntities {
    id: number;

    @Type(() => Date)
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date;

    constructor(value: Partial<DatabaseEntities>) {
        if (value) {
            Object.assign(this, value);
        }
    }
}
