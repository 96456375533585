import { Component, Inject, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { NiceAlertConfig } from "@recursyve/nice-ui-kit.v2";

@Component({
    selector: "nordicite-custom-alert",
    templateUrl: "./custom-alert.template.html",
    encapsulation: ViewEncapsulation.None
})
export class CustomAlertComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public config: NiceAlertConfig,
        private matDialogRef: MatDialogRef<CustomAlertComponent>
    ) {}

    public confirm(): void {
        this.matDialogRef.close(true);
    }

    public cancel(): void {
        this.matDialogRef.close(false);
    }
}
