import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { BreadcrumbService } from "@recursyve/ngx-panko";

@Component({
    selector: "nordicite-home",
    templateUrl: "./home.template.html",
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
    constructor(private breadcrumbService: BreadcrumbService) {}

    public ngOnInit(): void {
        this.breadcrumbService.setItems([]);
    }
}
