import { Store, StoreConfig } from "@datorama/akita";
import { Injectable } from "@angular/core";

export interface SignInOrganizationState {}

const initialValue: SignInOrganizationState = {};

@Injectable()
@StoreConfig({ name: "sign-in-organization", resettable: true })
export class SignInOrganizationStore extends Store<SignInOrganizationState> {
    constructor() {
        super(initialValue);
    }
}
