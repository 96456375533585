import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

type Constructor<T = any> = new (...args: any[]) => T;
type AbstractConstructor<T = any> = abstract new (...args: any[]) => T;

export const UnsubscribeAllMixin = <T extends Constructor | AbstractConstructor>(Base?: T) =>
    class extends (Base ?? Object) implements OnDestroy {
        protected readonly unsubscribeAll$ = new Subject<void>();

        public ngOnDestroy(): void {
            super["ngOnDestroy"]?.();
            this.unsubscribeAll$.next();
            this.unsubscribeAll$.complete();
        }
    };
