import { EquipmentEntries } from "../models/equipment-entries.model";
import { OrganizationData } from "../../data-entries/models/organization-data.model";
import { Type } from "class-transformer";

export class EquipmentEntryFilterResult {
    page: number;
    length: number;
    nextPage: number;
    total: number;

    @Type(() => EquipmentEntryFilterValue)
    values: EquipmentEntryFilterValue[];
}

export class EquipmentEntryFilterValue {
    @Type(() => EquipmentEntries)
    equipmentEntry: EquipmentEntries;

    @Type(() => OrganizationData)
    data: OrganizationData[];
}
