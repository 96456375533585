import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { FindByNameApi } from "../settings/interfaces/find-by-name-api.interface";
import { UpsertSettingDto } from "../settings/dto/upsert-setting.dto";
import { Observable } from "rxjs";
import { UnitTypes } from "./models/unit-types.model";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";

@Injectable()
export class UnitTypesApi extends NordiciteBaseFilterApi<UnitTypesApi> implements FindByNameApi {
    constructor(http: HttpClient) {
        super("unit-type", http);
    }

    public create(dto: UpsertSettingDto): Observable<UnitTypes> {
        return this.http.post<UnitTypes>(this.url(), dto);
    }

    public getAll(): Observable<UnitTypes[]> {
        return this.http.get<UnitTypes[]>(this.url());
    }

    public update(id: number, dto: UpsertSettingDto): Observable<UnitTypes> {
        return this.http.put<UnitTypes>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<UnitTypes> {
        return this.http.put<UnitTypes>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }
}
