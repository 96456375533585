import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Countries } from "./models/countries.model";
import { States } from "./models/states.model";

@Injectable()
export class CountriesApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("country");
    }

    public getAll(): Observable<Countries[]> {
        return this.http.get<Countries[]>(this.url());
    }

    public getAllStatesForCountry(countryId: number): Observable<States[]> {
        return this.http.get<States[]>(this.url(`${countryId}/state`));
    }
}
