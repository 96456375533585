import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { DataSources } from "../data-sources/models/data-sources.model";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UpdateDataSourceDto } from "../data-sources/dto/update-data-source.dto";

export class OrganizationDataSourcesApi extends NordiciteBaseFilterApi<DataSources> {
    constructor(id: number, http: HttpClient) {
        super(`organization/${id}/data-source`, http);
    }

    public getDataSourceByName(name: string): Observable<DataSources> {
        return this.http.get<DataSources>(this.url(`name/${name}`));
    }

    public createDataSource(dto: UpdateDataSourceDto): Observable<DataSources> {
        return this.http.post<DataSources>(this.url(), dto);
    }
}
