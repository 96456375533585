<div [formGroup]="formGroup">
    <mat-form-field niceControlStatus appearance="outline" class="w-full">
        <mat-label>{{ "features.forgot_password.form.email" | translate }}</mat-label>
        <input
            matInput
            formControlName="email"
            type="text"
            class="flex-auto"
            niceAutofocus
        />
    </mat-form-field>
</div>
