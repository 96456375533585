import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FlagsmithService } from "./services/flagsmith.service";
import { FlagsmithFeatureDirective } from "./directives/flagsmith-feature.directive";
import { FlagsmithFeatureGuard } from "./guards/flagsmith-feature.guard";
import { FlagsmithFeatureFlagNavigationResolver } from "./providers/flagsmith-feature-flag-navigation.resolver";

@NgModule({
    declarations: [FlagsmithFeatureDirective],
    exports: [FlagsmithFeatureDirective],
    providers: [
        FlagsmithService,
        {
            provide: APP_INITIALIZER,
            useFactory: (service: FlagsmithService) => service.init,
            deps: [FlagsmithService],
            multi: true
        },
        FlagsmithFeatureGuard,
        FlagsmithFeatureFlagNavigationResolver
    ]
})
export class FlagsmithModule {}
