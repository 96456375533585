import { DatabaseEntities } from "../../common/models/database-entities.model";
import { AlertConfigurationValidatorTypes } from "../enums/alert-configuration-validator-types.enum";
import {
    AlertConfigurationValidatorBetweenTypeConfig,
    AlertConfigurationValidatorGreaterThanTypeConfig,
    AlertConfigurationValidatorLessThanTypeConfig,
    AlertConfigurationValidatorTypeConfig
} from "./sub-models/alert-configuration-validator-type-config.interface";
import { Type } from "class-transformer";

export class AlertConfigurationValidators extends DatabaseEntities {
    alertConfigurationId: number;
    type: AlertConfigurationValidatorTypes;

    @Type(({ object }) => {
        switch ((object as AlertConfigurationValidators).type) {
            case AlertConfigurationValidatorTypes.GreaterThan:
                return AlertConfigurationValidatorGreaterThanTypeConfig;
            case AlertConfigurationValidatorTypes.LessThan:
                return AlertConfigurationValidatorLessThanTypeConfig;
            case AlertConfigurationValidatorTypes.Between:
                return AlertConfigurationValidatorBetweenTypeConfig;
        }
    })
    typeConfig: AlertConfigurationValidatorTypeConfig;
}
