import { Injectable } from "@angular/core";
import { NavigationHintResolver } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { filterNilValue } from "@datorama/akita";
import { map } from "rxjs/operators";
import { AppQuery } from "../../../../../../store/app.query";

@Injectable({ providedIn: "root" })
export class OrganizationLaboratoriesNavigationItemHintResolver extends NavigationHintResolver {
    constructor(private appQuery: AppQuery) {
        super();
    }

    public shouldShowHint(): Observable<boolean> {
        return this.appQuery.selectCurrentOrganizationLaboratoriesConfigurationStatus().pipe(
            filterNilValue(),
            map(
                organizationLaboratoriesConfigurationStatus =>
                    organizationLaboratoriesConfigurationStatus.pendingOrganizationLaboratoryConfigurationCount > 0
            )
        );
    }
}
