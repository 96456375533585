import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { GenerateReportResultTablesDto } from "./dto/generate-report-result-tables.dto";
import { Observable } from "rxjs";

export class ReportResultFilesApi extends _NordiciteBaseApi {
    constructor(reportId: string, http: HttpClient) {
        super(`report/${reportId}/result/file`, http);
    }

    public generateReportResultTables(dto: GenerateReportResultTablesDto): Observable<HttpResponse<Blob>> {
        return this.httpClient.post(this.url("table"), dto, { responseType: "blob", observe: "response" });
    }
}
