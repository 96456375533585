import { Injectable } from "@angular/core";
import { NiceAsyncTypeaheadProvider, NiceAsyncTypeaheadSearchResult } from "@recursyve/nice-ui-kit.v2";
import { lastValueFrom } from "rxjs";
import { Attributes } from "./models/attributes.model";
import { ApiService } from "../../api.service";
import { transformTypeaheadSearchResult } from "../../../rx-pipes/transform-typeahead-search-result.pipe";
import { AttributeConfigTypes } from "./interfaces/attribute-config.interface";

export interface AttributesTypeaheadOptions {
    includeDisabled?: boolean;
    storageUnitConvertibleFromUnitId?: number;
    types?: AttributeConfigTypes | AttributeConfigTypes[];
    unitless?: boolean;
}

@Injectable()
export class AttributesTypeaheadApi extends NiceAsyncTypeaheadProvider<Attributes, number, AttributesTypeaheadOptions> {
    public resource = "attributes";
    public pageSize = 15;

    constructor(private api: ApiService) {
        super();
    }

    public async search(
        searchQuery: string,
        page: number,
        options?: AttributesTypeaheadOptions
    ): Promise<NiceAsyncTypeaheadSearchResult<Attributes>> {
        return lastValueFrom(
            this.api.attributes
                .search({
                    ...options,
                    searchQuery,
                    page,
                    pageSize: this.pageSize
                })
                .pipe(transformTypeaheadSearchResult(page, this.pageSize))
        );
    }

    public getById(id: number): Promise<Attributes> {
        return lastValueFrom(this.api.attributes.getById(id));
    }

    public format(item: Attributes): string {
        return item.name as string;
    }
}
