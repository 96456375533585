import { Injectable } from "@angular/core";
import { NiceFilterGroupService } from "@recursyve/nice-data-filter-kit";
import { NiceBaseListIcons } from "@recursyve/nice-data-filter-kit/lib/components/base-list/models/icons.model";

export enum FilterGroups {
    Alert = "alert",
    DataSource = "data_source",
    Organization = "organization",
    Equipment = "equipment",
    H2labAnalysisCertificate = "h2lab_analysis_certificate",
    User = "user",
    Process = "process",
    Attribute = "attribute",
    EquipmentCategory = "equipment_category",
    UnitType = "unit_type",
    Unit = "unit",
    UnitConversionFunction = "unit_conversion_function",
    Mandate = "mandate",
    Journal = "journal",
    JournalDataSource = "journal_data_source",
    JournalIntervention = "journal_intervention",
    EurofinsAnalysisCertificate = "eurofins_analysis_certificate",
    ReportResult = "report_result",
    Report = "report",
    Incident = "incident"
}

@Injectable({ providedIn: "root" })
export class FilterGroupService extends NiceFilterGroupService<FilterGroups> {
    public getFilterGroupIconClass(group: FilterGroups): NiceBaseListIcons {
        switch (group) {
            case FilterGroups.Alert:
                return { svgIcon: "streamline-regular:alert-bell-notification-1" };
            case FilterGroups.DataSource:
                return { svgIcon: "streamline-regular:folder" };
            case FilterGroups.Organization:
                return { svgIcon: "streamline-regular:hierarchy-6" };
            case FilterGroups.Equipment:
                return { svgIcon: "streamline-regular:tools-wrench-1" };
            case FilterGroups.User:
                return { svgIcon: "streamline-regular:single-neutral-circle" };
            case FilterGroups.Process:
                return { svgIcon: "streamline-regular:hierarchy-3" };
            case FilterGroups.Attribute:
                return { svgIcon: "streamline-regular:layout-corners-dashboard-1" };
            case FilterGroups.EquipmentCategory:
                return { svgIcon: "streamline-regular:list-bullets-1" };
            case FilterGroups.H2labAnalysisCertificate:
                return { svgIcon: "streamline-regular:book-next-page" };
            case FilterGroups.UnitType:
                return { svgIcon: "streamline-regular:ruler" };
            case FilterGroups.Unit:
                return { svgIcon: "streamline-regular:sigma" };
            case FilterGroups.UnitConversionFunction:
                return { svgIcon: "streamline-regular:sigma" };
            case FilterGroups.Mandate:
                return { svgIcon: "streamline-regular:pen-write-paper" };
            case FilterGroups.Journal:
                return { svgIcon: "streamline-regular:book-next-page" };
            case FilterGroups.JournalDataSource:
                return { svgIcon: "streamline-regular:book-next-page" };
            case FilterGroups.JournalIntervention:
                return { svgIcon: "streamline-regular:book-next-page" };
            case FilterGroups.EurofinsAnalysisCertificate:
                return { svgIcon: "streamline-regular:book-next-page" };
            case FilterGroups.ReportResult:
                return { svgIcon: "streamline-regular:workflow-data-table" };
            case FilterGroups.Report:
                return { svgIcon: "streamline-regular:workflow-data-table" };
            case FilterGroups.Incident:
                return { svgIcon: "streamline-regular:warning-fill-none" };
        }
    }
}
