import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { forkJoin, Observable, of, switchMap } from "rxjs";
import { AppService } from "../store/app.service";
import { UserOrganizationService } from "../providers/user-organization.service";
import { map } from "rxjs/operators";

@Injectable()
export class AuthenticatedGuard implements CanActivate {
    constructor(
        private authenticationService: NiceAuthenticationService,
        private appService: AppService,
        private userOrganizationService: UserOrganizationService,
        private router: Router
    ) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.authenticationService.checkLoggedIn().pipe(
            switchMap(authenticated => {
                if (!authenticated) {
                    return of(this.router.createUrlTree(["/sign-in"]));
                }
                return this.authenticatedRedirection();
            })
        );
    }

    private authenticatedRedirection(): Observable<boolean | UrlTree> {
        return forkJoin([
            this.userOrganizationService.loadUserOrganization(),
            this.appService.loadCurrentAccount()
        ]).pipe(
            switchMap(([userOrganization, account]) => {
                if (!account) {
                    return of(false);
                }

                if (!account.organization) {
                    return of(true);
                }

                if (!userOrganization && account.organization?.slug) {
                    return this.userOrganizationService
                        .setUserOrganization(account.organization.slug)
                        .pipe(map(() => true));
                } else if (
                    !account ||
                    account.organization.slug?.toLowerCase() !== userOrganization.slug?.toLowerCase()
                ) {
                    return this.appService.logout().pipe(map(() => this.router.createUrlTree(["/sign-in"])));
                }

                return of(true);
            })
        );
    }
}
