import { _NordiciteBaseFilterApi } from "../../nordicite-base-filter.api";
import { H2labAnalysisCertificates } from "./models/h2lab-analysis-certificates.model";
import { HttpClient } from "@angular/common/http";
import { FilterQueryModel } from "@recursyve/nice-data-filter-kit";
import { Observable } from "rxjs";
import { SignedUrl } from "../../common/models/signed-url.model";
import { GetH2labAnalysisCertificateFilterFileSignedUrlDto } from "./dto/get-h2lab-analysis-certificate-filter-file-signed-url.dto";

export class H2labAnalysisCertificatesApi extends _NordiciteBaseFilterApi(H2labAnalysisCertificates) {
    constructor(organizationLaboratoryId: number, http: HttpClient) {
        super(`organization-laboratory/${organizationLaboratoryId}/h2lab/analysis-certificate`, http);
    }

    public getFilterFilesSignedUrl(
        dto: GetH2labAnalysisCertificateFilterFileSignedUrlDto,
        filterQuery: FilterQueryModel
    ): Observable<SignedUrl> {
        return this.post("filter/signed-url", filterQuery, {
            params: dto
        });
    }
}
