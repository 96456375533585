import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { FindEscalationLevelsDto } from "./dto/find-escalation-levels.dto";
import { Observable } from "rxjs";
import { EscalationLevels } from "./models/escalation-levels.model";
import { Injectable } from "@angular/core";

@Injectable()
export class EscalationLevelsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("escalation-level", http);
    }

    public findAll(dto?: FindEscalationLevelsDto): Observable<EscalationLevels[]> {
        return this.get("", { params: { ...dto }, type: EscalationLevels });
    }
}
