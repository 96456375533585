import { Injectable } from "@angular/core";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Colors } from "./models/colors.model";

@Injectable()
export class ColorsApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("color");
    }

    public getAll(): Observable<Colors[]> {
        return this.http.get<Colors[]>(this.url());
    }
}
