import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UpsertMandateDto } from "../mandates/dto/upsert-mandate.dto";
import { Mandates } from "../mandates/models/mandates.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";

export class OrganizationMandatesApi extends NordiciteBaseFilterApi<Mandates> {
    constructor(id: number, http: HttpClient) {
        super(`organization/${id}/mandate`, http);
    }

    public createMandate(dto: UpsertMandateDto): Observable<Mandates> {
        return this.http.post<Mandates>(this.url(), dto);
    }

    public generateFileNumber(): Observable<{ fileNumber: string }> {
        return this.http.get<{ fileNumber: string }>(this.url("file-number"));
    }
}
