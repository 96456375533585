<nordicite-sign-in-card
    titlePath="features.setup_password.error.title"
    subTitlePath="features.setup_password.error.subtitle"
>
    <button mat-flat-button color="primary" class="w-full" [routerLink]="['/sign-in']">
        <span class="px-8">
            {{ "features.setup_password.error.connection" | translate }}
        </span>
    </button>
</nordicite-sign-in-card>
