<nice-layout [niceLoadingOverlay]="loading$ | async">
    <ng-container *ngIf="(emailSent$ | async) === false; else emailSent">
        <nordicite-sign-in-card
            titlePath="features.forgot_password.title"
            subTitlePath="features.forgot_password.subtitle"
        >
            <nordicite-forgot-password-form
                #form
                (submit)="submit($event)"
                class="pb-8 w-75"
            ></nordicite-forgot-password-form>
            <ng-container buttons>
                <button mat-flat-button color="primary" niceFormSubmit [form]="form" class="w-full">
                        <span class="px-8">
                            {{ "general.submit" | translate }}
                        </span>
                </button>
            </ng-container>
            <ng-template [ngTemplateOutlet]="backToLoginButton"></ng-template>
        </nordicite-sign-in-card>
    </ng-container>
</nice-layout>

<ng-template #emailSent>
    <nordicite-sign-in-card
        titlePath="features.forgot_password.email_sent.title"
        subTitlePath="features.forgot_password.email_sent.subtitle"
    >
        <div class="flex flex-col pt-12 pb-16 icon-circle justify-center items-center">
            <div class="pt-4 pl-2">
                <mat-icon svgIcon="streamline-regular:check-1" class="scale-[3]"></mat-icon>
            </div>
        </div>
        <div class="pt-4">
            <ng-template [ngTemplateOutlet]="backToLoginButton"></ng-template>
        </div>
    </nordicite-sign-in-card>
</ng-template>

<ng-template #backToLoginButton>
    <div class="pt-4 w-full">
        <button mat-flat-button class="w-full" (click)="backToLogin()">
            <span class="px-8">
                {{ "features.forgot_password.back_to_login" | translate }}
            </span>
        </button>
    </div>
</ng-template>
