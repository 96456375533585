import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { CreateIndicatorDto } from "./dto/create-indicator.dto";
import { Observable } from "rxjs";
import { Indicators } from "./models/indicators.model";
import { FindIndicatorViewIndicatorsDto } from "./dto/find-indicators.dto";

export class IndicatorViewIndicatorsApi extends _NordiciteBaseApi {
    constructor(indicatorViewId: number, http: HttpClient) {
        super(`indicator-view/${indicatorViewId}/indicator`, http);
    }

    public createIndicator(dto: CreateIndicatorDto): Observable<Indicators> {
        return this.post("", dto, { type: Indicators });
    }

    public findIndicators(dto?: FindIndicatorViewIndicatorsDto): Observable<Indicators[]> {
        return this.get("", { type: Indicators, params: { ...dto } });
    }
}
