import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Equipments } from "./equipments.model";
import { Accounts } from "../../accounts/models/accounts.model";
import { Type } from "class-transformer";

export class EquipmentEntries extends DatabaseEntities {
    equipmentId: number;
    accountId: number;

    @Type(() => Date)
    submittedAt: Date;

    equipment: Equipments;
    account: Accounts;
}
