import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { NiceNavigationService, NiceVerticalNavigationComponent } from "@recursyve/nice-ui-kit.v2";
import { AppService } from "../../store/app.service";
import { AppQuery } from "../../store/app.query";
import { NavigationEnd, Router, RoutesRecognized } from "@angular/router";
import { combineLatest, startWith, Subject, takeUntil } from "rxjs";
import { ToolbarItem } from "./components/toolbar/toolbar.item";
import { RolesEnum } from "../../api/nordicite/accounts/enums/roles.enum";

@Component({
    selector: "nordicite-dashboard",
    templateUrl: "dashboard.template.html",
    styleUrls: ["dashboard.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {
    public SHOW_NAV_ROUTE = "/dashboard/organization/";
    public DASHBOARD_ROUTE = "/dashboard";

    public currentAccount$ = this.appQuery.selectCurrentAccount();
    public currenOrganization$ = this.appQuery.selectCurrentOrganization();
    public shouldShowNav$ = this.appQuery.selectShouldShowNav();

    protected readonly RolesEnum = RolesEnum;

    private unsubscribeAll$ = new Subject<void>();
    public get navigation(): NiceVerticalNavigationComponent {
        return this.niceNavigationService.getComponent("mainNavigation");
    }

    constructor(
        private niceNavigationService: NiceNavigationService,
        private appService: AppService,
        private appQuery: AppQuery,
        private router: Router
    ) {}

    public ngOnInit(): void {
        combineLatest([
            this.appService.loadCurrentAccount(),
            this.appService.loadUserHasMultipleOrganizations()
        ]).subscribe(() => this.handleNavAndToolBar());
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
    }

    private handleNavAndToolBar(): void {
        const { currentAccount } = this.appQuery.getValue();

        if (!currentAccount?.role?.isOrganizationRole) {
            this.router.events
                .pipe(
                    takeUntil(this.unsubscribeAll$),
                    startWith(new RoutesRecognized(null, this.router.url, null, null))
                )
                .subscribe(event => {
                    if (event instanceof NavigationEnd || event instanceof RoutesRecognized) {
                        if (event.url === this.DASHBOARD_ROUTE) {
                            this.appService.updateCurrentToolbarItem(ToolbarItem.Organizations);
                        }

                        const shouldShowNav = event.url.startsWith(this.SHOW_NAV_ROUTE);
                        this.handleNav(shouldShowNav);
                        this.appService.updateShouldShowNav(shouldShowNav);
                    }
                });
        } else {
            this.appService.updateShouldShowNav(true);
        }
    }

    private handleNav(shouldShowNav: boolean): void {
        if (shouldShowNav) {
            this.navigation.open();
        } else {
            this.navigation.close();
        }
    }
}
