import { DatabaseEntities } from "../../common/models/database-entities.model";
import { DataSources } from "../../data-sources/models/data-sources.model";
import { Processes } from "./processes.model";
import { EquipmentCategories } from "./equipment-categories.model";
import { EquipmentAttributes } from "./equipment-attributes.model";
import { Accounts } from "../../accounts/models/accounts.model";
import { Type } from "class-transformer";

export class Equipments extends DatabaseEntities {
    dataSourceId: number;
    processId: number;
    categoryId: number;
    name: string;
    rank: string;
    disabledAt?: Date;
    disabledByAccountId: number;

    disabledByAccount?: Accounts;
    dataSource: DataSources;
    process: Processes;
    category: EquipmentCategories;

    @Type(() => EquipmentAttributes)
    equipmentAttributes: EquipmentAttributes[];
}
