import { Injectable } from "@angular/core";
import { CanActivate, Router, UrlTree } from "@angular/router";
import { NiceAuthenticationService } from "@recursyve/nice-auth0-kit";
import { defaultIfEmpty, Observable, of, switchMap } from "rxjs";
import { AppService } from "../store/app.service";
import { map } from "rxjs/operators";

@Injectable()
export class NotAuthenticatedGuard implements CanActivate {
    constructor(
        private authenticationService: NiceAuthenticationService,
        private router: Router,
        private appService: AppService
    ) {}

    public canActivate(): Observable<boolean | UrlTree> {
        return this.authenticationService.checkLoggedIn().pipe(
            switchMap(authenticated => {
                if (!authenticated) {
                    return of(true);
                }

                return this.appService.loadCurrentAccount().pipe(
                    defaultIfEmpty(() => null),
                    map(account => {
                        if (!account) {
                            return true;
                        }

                        return this.router.createUrlTree(["/dashboard"]);
                    })
                );
            })
        );
    }
}
