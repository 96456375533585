import { Directive, HostListener } from "@angular/core";
import { Location } from "@angular/common";

@Directive({
    selector: "[nordiciteLocationBack]"
})
export class LocationBackDirective {
    constructor(private location: Location) {}

    @HostListener("click")
    public onClick(): void {
        this.location.back();
    }
}
