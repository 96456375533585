import { Component, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { PasswordForm } from "./password-form.form";
import { NiceBaseFormComponent } from "@recursyve/nice-ui-kit.v2";

@Component({
    selector: "nordicite-password-form",
    templateUrl: "./password-form.template.html",
    encapsulation: ViewEncapsulation.None,
    providers: NgxFormGeneratorProvider.forFeature([PasswordForm])
})
export class PasswordFormComponent extends NiceBaseFormComponent<PasswordForm> {
    constructor(public formGroup: GeneratedFormGroup<PasswordForm>) {
        super(formGroup);
    }
}
