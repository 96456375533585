// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    demoOrganizationId: 260,
    production: true,
    applicationUrl: "https://nordicite.recursyve.dev",
    applicationOauthPath: "/oauth/login",
    apiUrl: "https://api.nordicite.recursyve.dev",
    sentryDsn: "https://8732fdd788174a8bb8c49ce592659729@o341312.ingest.sentry.io/6252967",
    sentryEnvironment: "staging",
    sentryReportErrors: true,
    sentryRelease: null,
    auth0: {
        domain: "https://nordicite-dev.us.auth0.com",
        clientId: "0lLX6YSUPt6HJqOc0nk2PO2GB8d9BI8E",
        clientSecret: "H6aR5kT-7jwz4myAD12hffLC4SlAFknVcIX704MDuzBobLRIq_c7QpJT8UsMgDg-",
        audience: "https://api.nordicite.recursyve.dev",
        scopes: ["openid", "profile", "email", "offline_access"]
    },
    firebase: {
        apiKey: "AIzaSyAyJYkH0kmPRkfhLGYOfnzG1XnIthTpY1k",
        authDomain: "nordicite-recursyve.firebaseapp.com",
        projectId: "nordicite-recursyve",
        storageBucket: "nordicite-recursyve.appspot.com",
        messagingSenderId: "750820603361",
        appId: "1:750820603361:web:5708b8c8035b6e1851cea0",
        measurementId: "G-1GKVMV1HG6"
    },
    syncfusionLicenseKey:
        "Mgo+DSMBaFt/QHRqVVhkVFpFdEBBXHxAd1p/VWJYdVt5flBPcDwsT3RfQF5jSnxRd0RnUXxeeHNRTg==;Mgo+DSMBPh8sVXJ0S0J+XE9AflRDX3xKf0x/TGpQb19xflBPallYVBYiSV9jS31TdUdhWH5aeHdVTmhUWQ==;ORg4AjUWIQA/Gnt2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZhW35fdHxWRmlcUUw=;MTQwOTI3NkAzMjMwMmUzNDJlMzBBMUZVaE5ta2ZsMDYwbk5jVjRwWlpQQnFCUzc1b2pEK0pJNnowUDJ6bVo0PQ==;MTQwOTI3N0AzMjMwMmUzNDJlMzBjZUY2TFpUOTZLTXNqbW1wcGV5QXpmVkNCN2xxTk5nYklJdXRjb3ptRnJNPQ==;NRAiBiAaIQQuGjN/V0Z+WE9EaFtKVmJLYVB3WmpQdldgdVRMZVVbQX9PIiBoS35RdUVgW31fcXBdRWBVV01+;MTQwOTI3OUAzMjMwMmUzNDJlMzBnN2dMeE1mNjhvdWNDQzYwMkE1M3dTUWtJWEZzYkU5OFdYUHdPY2JDRXlnPQ==;MTQwOTI4MEAzMjMwMmUzNDJlMzBHNlBzcTZNMGJrQ2M4NEpwaWw5QnpwUktDMUcyelJ5V2R3RTdwdUdCc0JvPQ==;Mgo+DSMBMAY9C3t2VVhkQlFacldJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxQdkZhW35fdHxWR2BUV0w=;MTQwOTI4MkAzMjMwMmUzNDJlMzBRZTdjZnVma3RxQnVTaW1kVkt0WGpZTjNtNnN4Vi85SGFCaUhCb2MwTlBFPQ==;MTQwOTI4M0AzMjMwMmUzNDJlMzBuNHpERVJEU3pGR2FNVk80RnBZaGRYNWVsOWNydGJQcmI3WUs0Z2pKekswPQ==;MTQwOTI4NEAzMjMwMmUzNDJlMzBnN2dMeE1mNjhvdWNDQzYwMkE1M3dTUWtJWEZzYkU5OFdYUHdPY2JDRXlnPQ==",
    flagsmithEnvironmentKey: "PhZZR7nr5HSHdUxBgiJgqQ",
    googleMapApiKey: "AIzaSyDhMb7fYurhqj_zA4hjDNR_twr3WNUUF4s",
    googleMapStyling: "43281859ebf89833"
};
