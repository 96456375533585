import { NordiciteBaseApi } from "../nordicite-base.api";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Accounts } from "../accounts/models/accounts.model";
import { RegisterAccountDto } from "./dto/register-account.dto";

@Injectable()
export class RegisterApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("register");
    }

    public registerProviderAccount(dto: RegisterAccountDto): Observable<Accounts> {
        return this.http.post<Accounts>(this.url(), dto);
    }
}
