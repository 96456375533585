import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { H2labAccountProjects } from "./h2lab-account-projects.model";
import { Type } from "class-transformer";

export class H2labLaboratories extends DatabaseEntities {
    organizationLaboratoryId?: number;

    @Type(() => H2labAccountProjects)
    accountProjects?: H2labAccountProjects[];

    public getPendingConfigurationCount(): number {
        if (!this.accountProjects?.length) {
            return 0;
        }

        return this.accountProjects.reduce(
            (pendingConfigurationCount, accountProject) =>
                pendingConfigurationCount + accountProject.getPendingConfigurationCount(),
            0
        );
    }
}
