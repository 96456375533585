import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { GeneratedFormGroup, NgxFormGeneratorProvider } from "@recursyve/ngx-form-generator";
import { SignInOrganizationForm } from "./sign-in-organization.form";
import { KeyboardCodes, niceAnimations } from "@recursyve/nice-ui-kit.v2";
import { SignInOrganizationQuery } from "./store/sign-in-organization.query";
import { SignInOrganizationService } from "./store/sign-in-organization.service";
import { Subject, takeUntil } from "rxjs";
import { Router } from "@angular/router";
import { filterNilValue } from "@datorama/akita";

@Component({
    selector: "nordicite-sign-in-slug",
    templateUrl: "sign-in-organization.template.html",
    styleUrls: ["sign-in-organization.scss"],
    encapsulation: ViewEncapsulation.None,
    animations: niceAnimations,
    providers: NgxFormGeneratorProvider.forFeature([SignInOrganizationForm])
})
export class SignInOrganizationComponent implements OnInit, OnDestroy {
    public loading$ = this.signInOrganizationQuery.selectLoading();
    public error$ = this.signInOrganizationQuery.selectError();

    private unsubscribeAll$ = new Subject<void>();

    constructor(
        public formGroup: GeneratedFormGroup<SignInOrganizationForm>,
        private signInOrganizationQuery: SignInOrganizationQuery,
        private singInOrganizationService: SignInOrganizationService,
        private router: Router
    ) {}

    @HostListener("keyup", ["$event"])
    public onChange(event: KeyboardEvent) {
        if (event) {
            this.singInOrganizationService.resetError();
            if (event.code === KeyboardCodes.Enter) {
                this.clickContinue();
            }
        }
    }

    public ngOnInit(): void {
        this.singInOrganizationService
            .init()
            .pipe(filterNilValue())
            .subscribe(() => {
                this.router.navigate(["sign-in", "auth"]);
            });
        this.error$.pipe(takeUntil(this.unsubscribeAll$)).subscribe(error => {
            if (error) {
                this.formGroup.markAllAsTouched();
            }
            this.formGroup.get("slug").setErrors(error);
        });
    }

    public ngOnDestroy(): void {
        this.unsubscribeAll$.next();
        this.unsubscribeAll$.complete();
        this.singInOrganizationService.reset();
    }

    public validate(): boolean {
        if (this.formGroup.valid) {
            return true;
        }
        this.formGroup.markAllAsTouched();
        return false;
    }

    public async clickContinue(): Promise<void> {
        if (!this.validate()) {
            return;
        }
        const { slug } = this.formGroup.getRawValue();
        this.singInOrganizationService
            .selectOrganization(slug)
            .pipe(filterNilValue())
            .subscribe(() => {
                this.router.navigate(["sign-in", "auth"]);
            });
    }

    public clickSignInWithPassword(): void {
        this.singInOrganizationService.signInWithPassword();
    }
}
