import { Component, Input, ViewEncapsulation } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "nordicite-base-modal",
    templateUrl: "./base-modal.template.html",
    styleUrls: ["./base-modal.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class BaseModalComponent {
    @Input()
    public title: string;

    @Input()
    public subtitle: string;

    @Input()
    public loading = false;

    constructor(private matDialogRef: MatDialogRef<any>) {}

    public close(): void {
        this.matDialogRef.close();
    }
}
