import { Injectable } from "@angular/core";
import { NavigationHintResolver } from "@recursyve/nice-ui-kit.v2";
import { Observable } from "rxjs";
import { filterNilValue } from "@datorama/akita";
import { map } from "rxjs/operators";
import { AppQuery } from "../../../../../../store/app.query";

@Injectable({ providedIn: "root" })
export class OrganizationReportsNavigationItemHintResolver extends NavigationHintResolver {
    constructor(private appQuery: AppQuery) {
        super();
    }

    public shouldShowHint(): Observable<boolean> {
        return this.appQuery.selectCurrentOrganizationReportsSignatureStatus().pipe(
            filterNilValue(),
            map(currentOrganizationReportSignatureStatus =>
                currentOrganizationReportSignatureStatus.some(
                    reportSignatureStatus => reportSignatureStatus.unsignedReportsCount > 5
                )
            )
        );
    }
}
