import { Injectable } from "@angular/core";
import { Store, StoreConfig } from "@datorama/akita";

export interface ResetPasswordState {
    emailSent: boolean;
}

const initialState: ResetPasswordState = {
    emailSent: false
};

@StoreConfig({ name: "forgot-password", resettable: true })
@Injectable()
export class ForgotPasswordStore extends Store<ResetPasswordState> {
    constructor() {
        super(initialState);
    }
}
