export const MAX_I32 = 2147483647;

export class MathUtils {
    public static roundToDecimalPlaces(value: number, places: number): number {
        return Math.round((value + Number.EPSILON) * 10 ** places) / 10 ** places;
    }

    public static roundToFixed(value: number, places: number): string {
        return this.roundToDecimalPlaces(value, places).toFixed(places);
    }

    public static clamp(value: number, min: number, max: number): number {
        return Math.max(Math.min(value, max), min);
    }
}
