import { Injectable } from "@angular/core";
import flagsmith from "flagsmith";
import { environment } from "../../../environments/environment";
import { IFlagsmithTrait } from "flagsmith/types";
import { NordiciteFeatureFlagKeys } from "../types/feature-flag-keys.type";

@Injectable({ providedIn: "root" })
export class FlagsmithService {
    public async init(): Promise<void> {
        await flagsmith.init({
            environmentID: environment.flagsmithEnvironmentKey,
            cacheFlags: true,
            enableAnalytics: true
        });
    }

    public async identify(userId: string, traits?: Record<string, IFlagsmithTrait>): Promise<void> {
        await flagsmith.identify(userId, traits);
    }

    public async logout(): Promise<void> {
        await flagsmith.logout();
    }

    public hasFeature(featureKey: NordiciteFeatureFlagKeys): boolean {
        return flagsmith.hasFeature(featureKey);
    }

    public getValue(featureKey: NordiciteFeatureFlagKeys): string {
        return flagsmith.getValue(featureKey);
    }
}
