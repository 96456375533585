import { _NordiciteBaseFilterApi } from "../../nordicite-base-filter.api";
import { EurofinsAnalysisCertificates } from "./models/eurofins-analysis-certificates.model";
import { HttpClient } from "@angular/common/http";
import { SignedUrl } from "../../common/models/signed-url.model";
import { Observable } from "rxjs";
import { FilterQueryModel } from "@recursyve/nice-data-filter-kit";

export class EurofinsAnalysisCertificatesApi extends _NordiciteBaseFilterApi(EurofinsAnalysisCertificates) {
    constructor(organizationLaboratoryId: number, http: HttpClient) {
        super(`organization-laboratory/${organizationLaboratoryId}/eurofins/analysis-certificate`, http);
    }

    public getFileSignedUrl(id: number): Observable<SignedUrl> {
        return this.get(`${id}/signed-url`);
    }

    public getFilterFileSignedUrl(filterQuery: FilterQueryModel): Observable<SignedUrl> {
        return this.post("filter/signed-url", filterQuery);
    }
}
