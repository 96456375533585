import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatDividerModule } from "@angular/material/divider";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { ToolbarComponent } from "./toolbar.component";
import { ToolbarItemComponent } from "./components/toolbar-item/toolbar-item.component";
import { DirectivesModule } from "../../../../directives/directives.module";
import { NiceStopPropagationModule } from "@recursyve/nice-ui-kit.v2";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        TranslateModule,

        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatDividerModule,
        DirectivesModule,
        NiceStopPropagationModule
    ],
    exports: [ToolbarComponent],
    declarations: [ToolbarComponent, ToolbarItemComponent]
})
export class ToolbarModule {}
