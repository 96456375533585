import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "nordicite-sign-in-card",
    templateUrl: "sign-in-card.template.html",
    styleUrls: ["sign-in-card.style.scss"],
    encapsulation: ViewEncapsulation.None
})
export class SignInCardComponent {
    @Input()
    public titlePath: string;

    @Input()
    public subTitlePath: string;
}
