import { Query } from "@datorama/akita";
import { SignInOrganizationState, SignInOrganizationStore } from "./sign-in-organization.store";
import { Injectable } from "@angular/core";

@Injectable()
export class SignInOrganizationQuery extends Query<SignInOrganizationState> {
    constructor(protected store: SignInOrganizationStore) {
        super(store);
    }
}
