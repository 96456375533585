import { IndicatorSeparatorTypeConfig } from "./indicator-separator-type-config.interface";
import { IndicatorGroupTypeConfig } from "./indicator-group-type-config.interface";
import { IndicatorSimpleValueTypeConfig } from "./indicator-simple-value-type-config.interface";
import { IndicatorRingTypeConfig } from "./indicator-ring-type-config.interface";
import { IndicatorLevelTypeConfig } from "./indicator-level-type-config.interface";
import { IndicatorGraphTypeConfig } from "./indicator-graph-type-config.interface";

export type IndicatorTypeConfigs =
    | IndicatorSeparatorTypeConfig
    | IndicatorGroupTypeConfig
    | IndicatorSimpleValueTypeConfig
    | IndicatorRingTypeConfig
    | IndicatorLevelTypeConfig
    | IndicatorGraphTypeConfig;

export abstract class IndicatorTypeConfig {}

export abstract class IndicatorTypeConfigWithVariablesFormula extends IndicatorTypeConfig {
    variables: IndicatorTypeConfigVariable[];
    formula: string;
}

export class IndicatorTypeConfigVariable {
    name: string;
    equipmentAttributeId: number;
}
