import { NgModule } from "@angular/core";
import { NavbarComponent } from "./navbar.component";
import { NiceAsyncTypeaheadModule, NiceNavigationModule, NiceStopPropagationModule } from "@recursyve/nice-ui-kit.v2";
import { MatFormFieldModule } from "@angular/material/form-field";
import { ReactiveFormsModule } from "@angular/forms";
import { DirectivesModule } from "../../../../directives/directives.module";
import { RouterModule } from "@angular/router";
import { MatDividerModule } from "@angular/material/divider";
import { CommonModule, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { ImagePlaceholderModule } from "../../../../components/image-placeholder/image-placeholder.module";
import { MatMenuModule } from "@angular/material/menu";

@NgModule({
    imports: [
        NiceNavigationModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        DirectivesModule,
        RouterModule,
        MatDividerModule,
        CommonModule,
        TranslateModule,
        NiceAsyncTypeaheadModule,
        MatIconModule,
        MatButtonModule,
        ImagePlaceholderModule,
        NgOptimizedImage,
        MatMenuModule,
        NiceStopPropagationModule
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})
export class NavbarModule {}
