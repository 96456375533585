import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { UnitConversions } from "./models/unit-conversions.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { map } from "rxjs/operators";
import { UpsertUnitConversionFunctionsDto } from "./dto/unit-conversion-functions.dto";

@Injectable()
export class UnitConversionsApi extends NordiciteBaseFilterApi<UnitConversions> {
    constructor(http: HttpClient) {
        super("unit/conversion", http);
    }

    public createUnitConversion(dto: UpsertUnitConversionFunctionsDto): Observable<UnitConversions> {
        return this.http.post<UnitConversions>(this.url(), dto);
    }

    public getBySourceUnitAndTargetUnit(
        sourceUnitIds: number[],
        targetUnitIds: number[]
    ): Observable<UnitConversions[]> {
        return this.http.get<UnitConversions[]>(this.url(), {
            params: {
                sourceUnitIds,
                targetUnitIds
            }
        });
    }

    public getMetadataBySourceUnitAndTargetUnit(sourceUnitId: number, targetUnitId: number): Observable<boolean> {
        return this.http
            .head<UnitConversions[]>(this.url(), {
                params: {
                    sourceUnitId,
                    targetUnitId
                }
            })
            .pipe(
                map(_ => true),
                catchError(err => {
                    if (err.status === 404) {
                        return of(false);
                    }
                    return throwError(err);
                })
            );
    }

    public updateUnitConversion(id: number, dto: UpsertUnitConversionFunctionsDto): Observable<UnitConversions> {
        return this.http.put<UnitConversions>(this.url(`${id}`), dto);
    }
}
