import { NgModule } from "@angular/core";
import { SetupPasswordComponent } from "./setup-password.component";
import { SetupPasswordQuery } from "./store/setup-password.query";
import { SetupPasswordService } from "./store/setup-password.service";
import { SetupPasswordStore } from "./store/setup-password.store";
import { NiceBaseFormModule, NiceLayoutModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { CommonModule } from "@angular/common";
import { SignInOrganizationModule } from "../sign-in/sign-in-organization/sign-in-organization.module";
import { SignInCardModule } from "../sign-in/components/sign-in-card/sign-in-card.module";
import { AccountInfoModule } from "./components/account-info/account-info.module";
import { MatButtonModule } from "@angular/material/button";
import { PasswordFormModule } from "../components/password-form/password-form.module";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { InvalidLinkModule } from "../components/invalid-link/invalid-link.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [SetupPasswordComponent],
    providers: [SetupPasswordQuery, SetupPasswordService, SetupPasswordStore],
    imports: [
        NiceLayoutModule,
        NiceLoadingSpinnerModule,
        CommonModule,
        SignInOrganizationModule,
        SignInCardModule,
        AccountInfoModule,
        NiceBaseFormModule,
        MatButtonModule,
        PasswordFormModule,
        TranslateModule,
        RouterModule,
        InvalidLinkModule,
        MatIconModule
    ],
    exports: [SetupPasswordComponent]
})
export class SetupPasswordModule {}
