import {
    EquipmentAttributeValidationContext,
    EquipmentAttributeValidationErrors,
    EquipmentAttributeValidator
} from "./validators/equipment-attribute-validator.model";
import { EquipmentAttributeDeltaLessThanValidator } from "./validators/equipment-attribute-delta-less-than-validator.model";
import { EquipmentAttributeGreaterThanValidator } from "./validators/equipment-attribute-greater-than-validator.model";
import { EquipmentAttributeBetweenValidator } from "./validators/equipment-attribute-between-validator.model";
import { EquipmentAttributeComputationConfig } from "../interfaces/equipment-attribute-computation-config.interface";
import { defaultIfEmpty, EMPTY, forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Type } from "class-transformer";

export enum EquipmentAttributeValidatorTypes {
    GreaterThan = "greater_than",
    Between = "between",
    DeltaLessThan = "delta_less_than"
}

export class EquipmentAttributeConfig {
    @Type(() => EquipmentAttributeValidator, {
        keepDiscriminatorProperty: true,
        discriminator: {
            property: "type",
            subTypes: [
                { name: EquipmentAttributeValidatorTypes.GreaterThan, value: EquipmentAttributeGreaterThanValidator },
                { name: EquipmentAttributeValidatorTypes.Between, value: EquipmentAttributeBetweenValidator },
                {
                    name: EquipmentAttributeValidatorTypes.DeltaLessThan,
                    value: EquipmentAttributeDeltaLessThanValidator
                }
            ]
        }
    })
    validators?: EquipmentAttributeValidator[];

    @Type(() => EquipmentAttributeComputationConfig)
    computation?: EquipmentAttributeComputationConfig;

    public validateValue(
        value: any,
        context: EquipmentAttributeValidationContext
    ): Observable<EquipmentAttributeValidationErrors> {
        if (!this.validators?.length) {
            return EMPTY;
        }

        return forkJoin(
            this.validators.map(validator => validator.validate(value, context).pipe(defaultIfEmpty(null)))
        ).pipe(
            map(errors => {
                const mergedErrors = {};
                for (const error of errors) {
                    if (error) {
                        Object.assign(mergedErrors, error);
                    }
                }

                return mergedErrors;
            })
        );
    }
}
