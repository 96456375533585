import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NordiciteBaseApi } from "../nordicite-base.api";
import { ExecuteFormulasDto } from "./dto/execute-formulas.dto";

@Injectable()
export class FormulasApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("formula");
    }

    public execute(dto: ExecuteFormulasDto): Observable<number> {
        return this.http.post<{ result: number }>(this.url("execute"), dto).pipe(map(x => x.result));
    }
}
