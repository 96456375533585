import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { NordiciteBaseApi } from "../../nordicite-base.api";
import { Observable } from "rxjs";
import { HealthAndSafetyRecords } from "../models/health-and-safety-records.model";
import { HealthAndSafetyPandaForm } from "../models/health-and-safety-panda-form.model";
import { BreadcrumbItems } from "@recursyve/ngx-panko";

@Injectable()
export class HealthAndSafetyRecordsApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("health-and-safety/record");
    }

    public getById(id: number): Observable<HealthAndSafetyRecords> {
        return this.http.get<HealthAndSafetyRecords>(this.url(`${id}`));
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.http.get<BreadcrumbItems[]>(this.url(`${id}/breadcrumb`));
    }

    public getForm(id: number): Observable<HealthAndSafetyPandaForm> {
        return this.http.get<HealthAndSafetyPandaForm>(this.url(`${id}/form`));
    }
}
