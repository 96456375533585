import { NordiciteBaseApi } from "../nordicite-base.api";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import { Journals } from "./models/journals.model";
import { CompleteJournalInterventionDto } from "./dto/complete-journal-intervention.dto";
import { JournalImageApi } from "./journal-image.api";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { UpdateJournalEntryDto } from "./dto/update-journal-entry.dto";
import { FilterQueryModel } from "@recursyve/nice-data-filter-kit";
import { FormDataUtils } from "@recursyve/nice-ui-kit.v2";

@Injectable()
export class JournalsApi extends NordiciteBaseApi {
    constructor(private http: HttpClient) {
        super("journal");
    }

    public images(journalId: number): JournalImageApi {
        return new JournalImageApi(journalId, this.http);
    }

    public getById(id: number): Observable<Journals> {
        return this.http.get<Journals>(this.url(`${id}`));
    }

    public updateJournalEntry(id: number, dto: UpdateJournalEntryDto): Observable<Journals> {
        return this.http.put<Journals>(this.url(`${id}`), dto);
    }

    public getBreadcrumbById(id: number): Observable<BreadcrumbItems[]> {
        return this.http.get<BreadcrumbItems[]>(this.url(`${id}/breadcrumb`));
    }

    public completeIntervention(
        id: number,
        dto: CompleteJournalInterventionDto,
        images?: File[]
    ): Observable<Journals> {
        const formData = new FormData();
        FormDataUtils.appendFormDataWithDto(formData, dto);
        images?.map(image => formData.append("images", image));
        return this.http.put<Journals>(this.url(`${id}/complete-intervention`), formData);
    }

    public filterDownloadResponse(filterQuery: FilterQueryModel): Observable<HttpResponse<Blob>> {
        return this.http.post(this.url("filter/download"), filterQuery, {
            responseType: "blob",
            observe: "response"
        });
    }
}
