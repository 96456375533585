import { NgModule } from "@angular/core";
import { SignInOrganizationModule } from "./sign-in-organization/sign-in-organization.module";
import { SignInAuthenticationModule } from "./sign-in-authentication/sign-in-authentication.module";
import { SignInComponent } from "./sign-in.component";
import { NiceLayoutModule } from "@recursyve/nice-ui-kit.v2";
import { RouterModule } from "@angular/router";

@NgModule({
    imports: [SignInOrganizationModule, SignInAuthenticationModule, NiceLayoutModule, RouterModule],
    declarations: [SignInComponent]
})
export class SignInModule {}
