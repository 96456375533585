import { IndicatorTypeConfig, IndicatorTypeConfigWithVariablesFormula } from "./indicator-type-config.interface";
import { GraphIndicatorLegendPositionsEnum } from "../enums/graph-indicator-legend-positions.enum";
import { GraphIndicatorYAxesEnum } from "../enums/graph-indicator-y-axes.enum";
import { GraphIndicatorThresholdOperatorsEnum } from "../enums/graph-indicator-threshold-operators.enum";
import { GraphIndicatorDurationTypesEnum } from "../enums/graph-indicator-duration-types.enum";
import { GraphIndicatorSeriesTypesEnum } from "../enums/graph-indicator-series-types.enum";
import { GraphIndicatorSeriesIntervalTypes } from "../enums/graph-indicator-series-interval-types.enum";
import { Type } from "class-transformer";

export class IndicatorGraphTypeConfigDuration {
    quantity: number;
    type: GraphIndicatorDurationTypesEnum;
}

export class IndicatorGraphTypeConfigXAxis {
    name?: string;

    @Type(() => IndicatorGraphTypeConfigDuration)
    defaultDuration: IndicatorGraphTypeConfigDuration;
    durationCustomizable: boolean;
}

export class IndicatorGraphTypeConfigYAxis {
    name: string;
    decimalPlaces?: number;
    min?: number;
    max?: number;
}

export class IndicatorGraphTypeConfigLegend {
    enabled: boolean;
    position: GraphIndicatorLegendPositionsEnum;
}

export class IndicatorGraphTypeConfigSeriesInterval {
    quantity: number;
    type: GraphIndicatorSeriesIntervalTypes;
}

export class IndicatorGraphTypeConfigSeries extends IndicatorTypeConfigWithVariablesFormula {
    name: string;
    unitId?: number;
    axis: GraphIndicatorYAxesEnum;
    types: GraphIndicatorSeriesTypesEnum[];
    hexColor: string;
    fillOpacity?: number;

    @Type(() => IndicatorGraphTypeConfigSeriesInterval)
    interval: IndicatorGraphTypeConfigSeriesInterval;
}

export class IndicatorGraphTypeConfigThreshold {
    name: string;
    value: number;
    operator: GraphIndicatorThresholdOperatorsEnum;
    axis: GraphIndicatorYAxesEnum;
    hexColor: string;
    fillOpacity?: number;
    showLine: boolean;
}

export class IndicatorGraphTypeConfig extends IndicatorTypeConfig {
    @Type(() => IndicatorGraphTypeConfigXAxis)
    xAxis: IndicatorGraphTypeConfigXAxis;

    @Type(() => IndicatorGraphTypeConfigYAxis)
    leftYAxis: IndicatorGraphTypeConfigYAxis;

    @Type(() => IndicatorGraphTypeConfigYAxis)
    rightYAxis?: IndicatorGraphTypeConfigYAxis;

    @Type(() => IndicatorGraphTypeConfigLegend)
    legend: IndicatorGraphTypeConfigLegend;

    @Type(() => IndicatorGraphTypeConfigSeries)
    series: IndicatorGraphTypeConfigSeries[];

    @Type(() => IndicatorGraphTypeConfigThreshold)
    thresholds: IndicatorGraphTypeConfigThreshold[];
}
