import { Injectable } from "@angular/core";
import { _NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Incidents } from "./models/incidents.models";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { GetIncidentDto } from "./dto/get-incident.dto";
import { IncidentUpdateStatusDto } from "./dto/incident-update-status.dto";

@Injectable()
export class IncidentsApi extends _NordiciteBaseFilterApi(Incidents) {
    constructor(http: HttpClient) {
        super("incident", http);
    }

    public getById(id: number, dto: GetIncidentDto): Observable<Incidents> {
        return this.get(`${id}`, {
            params: {
                ...dto
            },
            type: Incidents
        });
    }

    public updateStatus(id: number, dto: IncidentUpdateStatusDto): Observable<Incidents> {
        return this.put(`${id}/status`, dto);
    }
}
