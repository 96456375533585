import { DatabaseEntities } from "../../../common/models/database-entities.model";
import { H2labSamples } from "./h2lab-samples.model";
import { H2labAnalysisCertificateFiles } from "./h2lab-analysis-certificate-files.model";

export class H2labAnalysisCertificates extends DatabaseEntities {
    sampleId?: number;

    files?: H2labAnalysisCertificateFiles[];
    sample?: H2labSamples;
}
