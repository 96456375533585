import { NgModule } from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { OrganizationReportsNavigationItemHintComponent } from "./organization-reports-navigation-item-hint.component";

@NgModule({
    declarations: [OrganizationReportsNavigationItemHintComponent],
    imports: [TranslateModule],
    exports: [OrganizationReportsNavigationItemHintComponent]
})
export class OrganizationReportsNavigationItemHintModule {}
