import { defer, Observable, OperatorFunction, tap } from "rxjs";
import { Store } from "@datorama/akita";
import * as Sentry from "@sentry/angular";

export function niceSetLoadingAndError<T>(store: Store): OperatorFunction<T, T> {
    return (source: Observable<any>) =>
        defer(() => {
            store.setLoading(true);
            store.setError(null);

            return source.pipe(
                tap({
                    complete() {
                        store.setLoading(false);
                    },
                    error(err) {
                        store.setLoading(false);
                        store.setError(err);
                        Sentry.captureException(err);
                    }
                })
            );
        });
}
