import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Equipments } from "./equipments.model";
import { Attributes } from "../../attributes/models/attributes.model";
import { Units } from "../../units/models/units.model";
import { EquipmentAttributeConfig } from "./equipment-attribute-config.model";
import { Accounts } from "../../accounts/models/accounts.model";
import { Type } from "class-transformer";

export class EquipmentAttributes extends DatabaseEntities {
    equipmentId: number;
    attributeId: number;
    unitId?: number;
    rank: string;

    @Type(() => EquipmentAttributeConfig)
    config: EquipmentAttributeConfig;

    @Type(() => Date)
    disabledAt?: Date;
    disabledByAccountId: number;

    disabledByAccount?: Accounts;
    equipment?: Equipments;
    attribute?: Attributes;
    unit?: Units;
}
