import { HttpClient } from "@angular/common/http";
import { catchError, Observable, of, throwError } from "rxjs";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { Alerts } from "../alerts/models/alerts.model";
import { UpsertAlertDto } from "../alerts/dto/upsert-alert.dto";
import { map } from "rxjs/operators";

export class OrganizationAlertsApi extends NordiciteBaseFilterApi<Alerts> {
    constructor(organizationId: number, http: HttpClient) {
        super(`organization/${organizationId}/alert`, http);
    }

    public create(dto: UpsertAlertDto): Observable<Alerts> {
        return this.http.post<Alerts>(this.url(), dto);
    }

    public alertExistsByName(name: string): Observable<boolean> {
        return this.http.head(this.url(), { params: { name } }).pipe(
            map(() => true),
            catchError(err => {
                if (err.status === 404) {
                    return of(false);
                }

                return throwError(err);
            })
        );
    }
}
