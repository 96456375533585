import { Injectable } from "@angular/core";
import { BreadcrumbItems, BreadcrumbRouteResolver } from "@recursyve/ngx-panko";
import * as inflection from "inflection";
import { AppQuery } from "../store/app.query";

export interface NordiciteBreadcrumbItem extends BreadcrumbItems {
    skipResourceNameNormalization: boolean;
}

@Injectable()
export class NiceBreadcrumbRouteResolver extends BreadcrumbRouteResolver {
    constructor(private appQuery: AppQuery) {
        super();
    }

    public getItemLink(currentItem: NordiciteBreadcrumbItem | null): any[] {
        if (!currentItem) {
            return ["/dashboard"];
        }

        const res: any[] = ["/dashboard"];
        const { currentOrganization } = this.appQuery.getValue();
        if (currentOrganization) {
            res.push("organization", currentOrganization?.slug);
        }

        if (currentItem.skipResourceNameNormalization) {
            res.push(currentItem.resourceName);
        } else {
            res.push(inflection.dasherize(inflection.singularize(currentItem.resourceName)));
        }

        if (currentItem.resourceId) {
            res.push(currentItem.resourceId);
        }

        return res;
    }
}
