import { NgModule } from "@angular/core";
import { ResetPasswordComponent } from "./reset-password.component";
import { ResetPasswordQuery } from "./store/reset-password.query";
import { ResetPasswordService } from "./store/reset-password.service";
import { ResetPasswordStore } from "./store/reset-password.store";
import { NiceBaseFormModule, NiceLayoutModule, NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { CommonModule } from "@angular/common";
import { SignInCardModule } from "../sign-in/components/sign-in-card/sign-in-card.module";
import { PasswordFormModule } from "../components/password-form/password-form.module";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { RouterModule } from "@angular/router";
import { InvalidLinkModule } from "../components/invalid-link/invalid-link.module";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [ResetPasswordComponent],
    providers: [ResetPasswordQuery, ResetPasswordService, ResetPasswordStore],
    imports: [
        NiceLayoutModule,
        NiceLoadingSpinnerModule,
        CommonModule,
        SignInCardModule,
        PasswordFormModule,
        MatButtonModule,
        NiceBaseFormModule,
        TranslateModule,
        RouterModule,
        InvalidLinkModule,
        MatIconModule
    ],
    exports: [ResetPasswordComponent]
})
export class ResetPasswordModule {}
