import { Injectable } from "@angular/core";
import { Query } from "@datorama/akita";
import { ForgotPasswordStore, ResetPasswordState } from "./forgot-password.store";
import { Observable } from "rxjs";

@Injectable()
export class ForgotPasswordQuery extends Query<ResetPasswordState> {
    constructor(store: ForgotPasswordStore) {
        super(store);
    }

    public selectEmailSent(): Observable<boolean> {
        return this.select(s => s.emailSent);
    }
}
