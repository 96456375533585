import { DatabaseEntities } from "../../common/models/database-entities.model";
import { Indicators } from "./indicators.model";
import { plainToInstance, Type } from "class-transformer";
import { ObjectUtils } from "@recursyve/nice-ui-kit.v2";
import { isDefined } from "class-validator";
import { IndicatorViewTypesEnum } from "../enums/indicator-view-types.enum";

export class IndicatorViews extends DatabaseEntities {
    organizationId: number;
    processId: number;
    type: IndicatorViewTypesEnum;

    @Type(() => Indicators)
    indicators: Indicators[];

    public clone(): IndicatorViews {
        return plainToInstance(IndicatorViews, ObjectUtils.deepCopy(this));
    }

    public upsertIndicatorInPlace(upsertedIndicator: Partial<Indicators> & Pick<Indicators, "id">): void {
        for (let i = this.indicators.length - 1; i >= 0; i--) {
            const indicator = this.indicators[i];

            if (indicator.id === upsertedIndicator.id) {
                if (isDefined(upsertedIndicator.parentIndicatorId)) {
                    this.indicators.splice(i, 1);
                } else {
                    Object.assign(indicator, upsertedIndicator);
                    return;
                }
            }

            indicator.upsertIndicatorInPlace(upsertedIndicator);
        }

        if (upsertedIndicator.parentIndicatorId === null) {
            this.indicators.push(upsertedIndicator as Indicators);
        }
    }

    public deleteIndicator(deletedIndicator: Partial<Indicators> & Pick<Indicators, "id">): void {
        for (let index = 0; index < this.indicators.length; index++) {
            if (this.indicators[index].id === deletedIndicator.id) {
                this.indicators.splice(index, 1);
                return;
            }

            if (this.indicators[index].deleteIndicator(deletedIndicator)) {
                return;
            }
        }
    }
}
