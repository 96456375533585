import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { _NordiciteBaseApi } from "../nordicite-base.api";
import { Reports } from "./models/reports.model";
import { BreadcrumbItems } from "@recursyve/ngx-panko";
import { UpdateReportStatusDto } from "./dto/update-report-status.dto";
import { UpsertReportDto } from "./dto/upsert-report.dto";
import { ReportResultFilesApi } from "./report-result-files.api";

@Injectable()
export class ReportsApi extends _NordiciteBaseApi {
    constructor(http: HttpClient) {
        super("report", http);
    }

    public reportResultFiles(reportId: string): ReportResultFilesApi {
        return new ReportResultFilesApi(reportId, this.httpClient);
    }

    public getById(id: string): Observable<Reports> {
        return this.get(id, { type: Reports });
    }

    public getBreadcrumbById(id: string): Observable<BreadcrumbItems[]> {
        return this.get(`${id}/breadcrumb`);
    }

    public update(id: string, dto: UpsertReportDto): Observable<Reports> {
        return this.put<Reports>(id, dto, { type: Reports });
    }

    public updateActiveStatus(id: string, dto: UpdateReportStatusDto): Observable<Reports> {
        return this.put<Reports>(`${id}/status`, dto, { type: Reports });
    }
}
