import { ValidatorsHandler } from "@recursyve/ngx-form-generator";
import { AbstractControl, ValidationErrors } from "@angular/forms";
import { isEmail } from "class-validator";

export function IsEmail(): PropertyDecorator {
    return ValidatorsHandler.setup((control: AbstractControl): ValidationErrors | null => {
        if (control.value == null || control.value.length === 0) {
            return null; // Don't validate empty values to allow optional controls
        }

        return isEmail(control.value, {}) ? null : { email: true };
    });
}
