import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { NiceLoadingSpinnerModule } from "@recursyve/nice-ui-kit.v2";
import { CommonModule } from "@angular/common";
import { BaseModalComponent } from "./base-modal.component";

@NgModule({
    declarations: [BaseModalComponent],
    imports: [MatButtonModule, MatIconModule, NiceLoadingSpinnerModule, CommonModule],
    exports: [BaseModalComponent]
})
export class BaseModalModule {}
