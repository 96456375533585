import { _NordiciteBaseApi } from "../nordicite-base.api";
import { HttpClient } from "@angular/common/http";
import { UpdateEscalationLevelsDto } from "../urgency-levels/dto/update-escalation-levels.dto";
import { Observable } from "rxjs";
import { EscalationLevels } from "../urgency-levels/models/escalation-levels.model";

export class OrganizationEscalationLevelsApi extends _NordiciteBaseApi {
    constructor(organizationId: number, httpClient: HttpClient) {
        super(`organization/${organizationId}/escalation-level`, httpClient);
    }

    public setOrganizationEscalationLevels(dto: UpdateEscalationLevelsDto): Observable<EscalationLevels[]> {
        return this.put("", dto);
    }
}
