import { Injectable } from "@angular/core";
import { CanLoad, CanMatch } from "@angular/router";
import { FlagsmithService } from "../services/flagsmith.service";
import { NordiciteRoute } from "../../types/route.type";

@Injectable({ providedIn: "root" })
export class FlagsmithFeatureGuard implements CanLoad, CanMatch {
    constructor(private flagsmithService: FlagsmithService) {}

    public canLoad(route: NordiciteRoute): boolean {
        return this.can(route);
    }

    public canMatch(route: NordiciteRoute): boolean {
        return this.can(route);
    }

    private can(route: NordiciteRoute): boolean {
        if (!route.data.featureKey) {
            throw new Error("No feature flag key was provided in the route data");
        }

        return this.flagsmithService.hasFeature(route.data.featureKey);
    }
}
