import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Units } from "./models/units.model";
import { NordiciteBaseFilterApi } from "../nordicite-base-filter.api";
import { UpsertUnitsDto } from "./dto/units.dto";
import { FindByNameApi } from "../settings/interfaces/find-by-name-api.interface";
import { UpdateActiveStatusDto } from "../organizations/dto/update-active-status.dto";
import { FindUnitsDto } from "./dto/find-units.dto";

@Injectable()
export class UnitsApi extends NordiciteBaseFilterApi<Units> implements FindByNameApi {
    constructor(http: HttpClient) {
        super("unit", http);
    }

    public create(dto: UpsertUnitsDto): Observable<Units> {
        return this.http.post<Units>(this.url(), dto, {
            params: {
                skipTranslation: true
            }
        });
    }

    public getById(id: number): Observable<Units> {
        return this.http.get<Units>(this.url(`${id}`));
    }

    public findAll(dto?: FindUnitsDto): Observable<Units[]> {
        return this.http.get<Units[]>(this.url(), {
            params: { ...dto }
        });
    }

    public search(dto?: FindUnitsDto): Observable<Units[]> {
        return this.http.get<Units[]>(this.url("search"), {
            params: { ...dto }
        });
    }

    public update(id: number, dto: UpsertUnitsDto): Observable<Units> {
        return this.http.put<Units>(this.url(`${id}`), dto, {
            params: { skipTranslation: true }
        });
    }

    public updateActiveStatus(id: number, dto: UpdateActiveStatusDto): Observable<Units> {
        return this.http.put<Units>(this.url(`${id}/status`), dto, {
            params: { skipTranslation: true }
        });
    }
}
