import { Directive, ElementRef, HostListener, Input, TemplateRef, ViewContainerRef } from "@angular/core";

@Directive({ selector: "img[nordiciteImageOnErrorFallback]" })
export class ImageOnErrorFallbackDirective {
    @Input()
    public fallback?: TemplateRef<any>;

    constructor(private elementRef: ElementRef, private viewContainerRef: ViewContainerRef) {}

    @HostListener("error")
    public onError(): void {
        this.elementRef.nativeElement.style.display = "none";
        if (this.fallback) {
            this.viewContainerRef.createEmbeddedView(this.fallback);
        }
    }
}
