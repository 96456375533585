import { NgModule } from "@angular/core";
import { ImagePlaceholderComponent } from "./image-placeholder.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [ImagePlaceholderComponent],
    imports: [MatIconModule],
    exports: [ImagePlaceholderComponent]
})
export class ImagePlaceholderModule {}
