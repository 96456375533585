import { Store } from "@datorama/akita";
import { defer, OperatorFunction, tap } from "rxjs";
import * as Sentry from "@sentry/angular";

export const niceSetError =
    <T>(store: Store): OperatorFunction<T, T> =>
    source =>
        defer(() => {
            store.setError(null);

            return source.pipe(
                tap({
                    error: error => {
                        store.setError(error);
                        Sentry.captureException(error);
                    }
                })
            );
        });
